import React from 'react';

function Memos() {
  return (
    <>
      <div className='container text-center'>
        <div className='row justify-content-center'>
          <div className='col-10 col-md-10 col-lg-8 col-xl-7'>
            <h1 className='display-4 text-primary mt-3 mb-2'>Memos</h1>
            <section className='memos'>
              <div className='memo'></div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default Memos;
