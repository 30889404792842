import React, { useState, useEffect } from "react";
import { Router, navigate } from "@reach/router";
import firebase from "./components/Firebase";
import { db } from "./components/Firebase";

import Navigation from "./components/Navigation";
import Auth from "./components/admin/Auth";
import CreateAssigment from "./components/admin/CreateAssignment";
import EditAssigmentOne from "./components/admin/assignments/EditAssignmentOne";
import EditAssigmentTwo from "./components/admin/assignments/EditAssignmentTwo";
import EditAssigmentThree from "./components/admin/assignments/EditAssignmentThree";
import EditAssigmentFour from "./components/admin/assignments/EditAssignmentFour";
import EditAssigmentFive from "./components/admin/assignments/EditAssignmentFive";
import EditAssigmentSix from "./components/admin/assignments/EditAssignmentSix";
import EditAssigmentSeven from "./components/admin/assignments/EditAssignmentSeven";
import EditAssigmentEight from "./components/admin/assignments/EditAssignmentEight";
import EditAssigmentNine from "./components/admin/assignments/EditAssignmentNine";
import EditAssigmentTen from "./components/admin/assignments/EditAssignmentTen";
import EditAssigmentEleven from "./components/admin/assignments/EditAssignmentEleven";
import EditAssigmentTwelve from "./components/admin/assignments/EditAssignmentTwelve";
import AssignmentsList from "./components/admin/AssignmentsList";
import StudentsList from "./components/admin/StudentsList";
import StudentProfile from "./components/admin/StudentProfile";
// import Welcome from "./components/Welcome";
import Week1 from "./components/assignments/Week1";
import Week1Response from "./components/assignments/Week1Response";
import Week2 from "./components/assignments/Week2";
import Week2Question from "./components/assignments/Week2Question";
import Week2Response from "./components/assignments/Week2Response";
import Week3 from "./components/assignments/Week3";
import Week3Question from "./components/assignments/Week3Question";
import Week3Response from "./components/assignments/Week3Response";
import Week4 from "./components/assignments/Week4";
import Week4Question from "./components/assignments/Week4Question";
import Week4Response from "./components/assignments/Week4Response";
import Week5 from "./components/assignments/Week5";
import Week5Question from "./components/assignments/Week5Question";
import Week5Response from "./components/assignments/Week5Response";
import Week6 from "./components/assignments/Week6";
import Week6Question from "./components/assignments/Week6Question";
import Week6Response from "./components/assignments/Week6Response";
import Week7 from "./components/assignments/Week7";
import Week7Question from "./components/assignments/Week7Question";
import Week7Response from "./components/assignments/Week7Response";
import Week8 from "./components/assignments/Week8";
import Week8Question from "./components/assignments/Week8Question";
import Week8Response from "./components/assignments/Week8Response";
import Week9 from "./components/assignments/Week9";
import Week9Question from "./components/assignments/Week9Question";
import Week9Response from "./components/assignments/Week9Response";
import Week10 from "./components/assignments/Week10";
import Week10Question from "./components/assignments/Week10Question";
import Week10Response from "./components/assignments/Week10Response";
import Week11 from "./components/assignments/Week11";
import Week11Question from "./components/assignments/Week11Question";
import Week11Response from "./components/assignments/Week11Response";
import Week12 from "./components/assignments/Week12";
import Week12Question from "./components/assignments/Week12Question";
import Week12Response from "./components/assignments/Week12Response";
import Home from "./components/Home";
import About from "./components/About";
import Login from "./components/Login";
import Register from "./components/Register";
import Workspace from "./components/Workspace";
import Memos from "./components/Memos";
import Team from "./components/Team";
import Directory from "./components/Directory";
import Profile from "./components/Profile";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";

const App = () => {
	const [user, setUser] = useState(null);
	const [displayName, setDisplayName] = useState(null);
	const [userID, setUserID] = useState(null);
	const [admin, setAdmin] = useState(null);

	useEffect(() => {
		firebase.auth().onAuthStateChanged(FBUser => {
			if (FBUser) {
				FBUser.getIdTokenResult().then(idTokenResult => {
					FBUser.admin = idTokenResult.claims.admin;
					setAdmin(FBUser.admin);
					// console.log(idTokenResult.claims.admin);
				});
				setUser(FBUser);
				// setDisplayName(FBUser.displayName);
				setUserID(FBUser.uid);
			}
		});
	}, []);

	// display name from users collection
	useEffect(() => {
		let usersRef = db.collection("users").doc(`${userID}`);
		usersRef
			.get()
			.then(doc => {
				if (doc.exists) {
					let data = doc.data();
					setDisplayName(data.displayName);
				}
			})
			.catch(error => {
				console.log("Error getting user", error);
			});
	}, [userID]);

	const registerUser = (userName, courseID) => {
		firebase.auth().onAuthStateChanged(FBUser => {
			FBUser.updateProfile({
				displayName: userName,
			})
				.then(() => {
					setUser(FBUser);
					setDisplayName(FBUser.displayName);
					setUserID(FBUser.uid);
				})
				.then(() => {
					return firebase.firestore().collection("users").doc(FBUser.uid).set({
						userID: FBUser.uid,
						displayName: FBUser.displayName,
						email: FBUser.email,
						courseID: courseID,
					});
				});
			navigate("/workspace");
		});
	};

	const logOutUser = e => {
		e.preventDefault();
		setDisplayName(null);
		setUserID(null);
		setUser(null);
		setAdmin(null);

		firebase
			.auth()
			.signOut()
			.then(() => {
				navigate("/");
			});
	};

	return (
		<>
			<Navigation
				user={user}
				userID={userID}
				logOutUser={logOutUser}
				admin={admin}
			/>
			{/* {user && (
        <Welcome
          displayName={displayName}
          admin={admin}
          logOutUser={logOutUser}
        />
      )} */}
			<Router>
				<Login path="/login" displayName={displayName} admin={admin} />
				<Home path="/" user={user} />
				<About path="/about" user={user} />
				<Register path="/register" registerUser={registerUser} />
				<Auth path="/admin/auth" admin={admin} />
				<CreateAssigment path="/admin/create-assignment" />
				<EditAssigmentOne path="/admin/assignments/edit-assignment-one" />
				<EditAssigmentTwo path="/admin/assignments/edit-assignment-two" />
				<EditAssigmentThree path="/admin/assignments/edit-assignment-three" />
				<EditAssigmentFour path="/admin/assignments/edit-assignment-four" />
				<EditAssigmentFive path="/admin/assignments/edit-assignment-five" />
				<EditAssigmentSix path="/admin/assignments/edit-assignment-six" />
				<EditAssigmentSeven path="/admin/assignments/edit-assignment-seven" />
				<EditAssigmentEight path="/admin/assignments/edit-assignment-eight" />
				<EditAssigmentNine path="/admin/assignments/edit-assignment-nine" />
				<EditAssigmentTen path="/admin/assignments/edit-assignment-ten" />
				<EditAssigmentEleven path="/admin/assignments/edit-assignment-eleven" />
				<EditAssigmentTwelve path="/admin/assignments/edit-assignment-twelve" />
				<AssignmentsList path="/admin/assignments-list" admin={admin} />
				<StudentsList path="/admin/students-list" user={user} admin={admin} />
				<StudentProfile path="/admin/student-profile/:id" />
				<Workspace path="/workspace" user={user} logOutUser={logOutUser} />
				<Week1
					path="/workspace/assignments/week-one"
					user={user}
					admin={admin}
				/>
				<Week1Response
					path="/workspace/assignments/week-one-response"
					user={user}
				/>
				<Week2 path="/workspace/assignments/week-two" user={user} />
				<Week2Question
					path="/workspace/assignments/week-two-question"
					user={user}
				/>
				<Week2Response
					path="/workspace/assignments/week-two-response"
					user={user}
				/>
				<Week3 path="/workspace/assignments/week-three" user={user} />
				<Week3Question
					path="/workspace/assignments/week-three-question"
					user={user}
				/>
				<Week3Response
					path="/workspace/assignments/week-three-response"
					user={user}
				/>
				<Week4 path="/workspace/assignments/week-four" user={user} />
				<Week4Question
					path="/workspace/assignments/week-four-question"
					user={user}
				/>
				<Week4Response
					path="/workspace/assignments/week-four-response"
					user={user}
				/>
				<Week5 path="/workspace/assignments/week-five" user={user} />
				<Week5Question
					path="/workspace/assignments/week-five-question"
					user={user}
				/>
				<Week5Response
					path="/workspace/assignments/week-five-response"
					user={user}
				/>
				<Week6 path="/workspace/assignments/week-six" user={user} />
				<Week6Question
					path="/workspace/assignments/week-six-question"
					user={user}
				/>
				<Week6Response
					path="/workspace/assignments/week-six-response"
					user={user}
				/>
				<Week7 path="/workspace/assignments/week-seven" user={user} />
				<Week7Question
					path="/workspace/assignments/week-seven-question"
					user={user}
				/>
				<Week7Response
					path="/workspace/assignments/week-seven-response"
					user={user}
				/>
				<Week8 path="/workspace/assignments/week-eight" user={user} />
				<Week8Question
					path="/workspace/assignments/week-eight-question"
					user={user}
				/>
				<Week8Response
					path="/workspace/assignments/week-eight-response"
					user={user}
				/>
				<Week9 path="/workspace/assignments/week-nine" user={user} />
				<Week9Question
					path="/workspace/assignments/week-nine-question"
					user={user}
				/>
				<Week9Response
					path="/workspace/assignments/week-nine-response"
					user={user}
				/>
				<Week10 path="/workspace/assignments/week-ten" user={user} />
				<Week10Question
					path="/workspace/assignments/week-ten-question"
					user={user}
				/>
				<Week10Response
					path="/workspace/assignments/week-ten-response"
					user={user}
				/>
				<Week11 path="/workspace/assignments/week-eleven" user={user} />
				<Week11Question
					path="/workspace/assignments/week-eleven-question"
					user={user}
				/>
				<Week11Response
					path="/workspace/assignments/week-eleven-response"
					user={user}
				/>
				<Week12 path="/workspace/assignments/week-twelve" user={user} />
				<Week12Question
					path="/workspace/assignments/week-twelve-question"
					user={user}
				/>
				<Week12Response
					path="/workspace/assignments/week-twelve-response"
					user={user}
				/>
				<Memos path="/memos" />
				<Team path="/team" />
				<Directory path="/directory" />
				<Profile path="/profile" user={user} admin={admin} />
			</Router>
		</>
	);
};

export default App;
