import React from "react";
import AssignmentOne from "./assignments/AssignmentOne";
import AssignmentTwo from "./assignments/AssignmentTwo";
import AssignmentThree from "./assignments/AssignmentThree";
import AssignmentFour from "./assignments/AssignmentFour";
import AssignmentFive from "./assignments/AssignmentFive";
import AssignmentSix from "./assignments/AssignmentSix";
import AssignmentSeven from "./assignments/AssignmentSeven";
import AssignmentEight from "./assignments/AssignmentEight";
import AssignmentNine from "./assignments/AssignmentNine";
import AssignmentTen from "./assignments/AssignmentTen";
import AssignmentEleven from "./assignments/AssignmentEleven";
import AssignmentTwelve from "./assignments/AssignmentTwelve";

const AssignmentsList = props => {
	const { admin } = props;
	console.log(admin);

	return (
		<>
			<div className="container text-center">
				<div className="row justify-content-center">
					<div className="col-12">
						<h1 className="display-4 text-primary mt-3 mb-2">
							Assignments List
						</h1>
						<AssignmentOne />
						<AssignmentTwo />
						<AssignmentThree />
						<AssignmentFour />
						<AssignmentFive />
						<AssignmentSix />
						<AssignmentSeven />
						<AssignmentEight />
						<AssignmentNine />
						<AssignmentTen />
						<AssignmentEleven />
						<AssignmentTwelve />
					</div>
				</div>
			</div>
		</>
	);
};

export default AssignmentsList;
