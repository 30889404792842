import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router";
import { db } from "../Firebase";

const Week2Question = props => {
	const { user } = props;
	let uid;
	if (user != null) {
		uid = user.uid;
	}
	const [choice, setChoice] = useState({
		weekTwoChoice: "",
	});
	const [data, setData] = useState({
		weeklyQuestion: "",
		choice1: "",
		choice2: "",
		choice3: "",
		choice4: "",
	});

	useEffect(() => {
		db.collection("users")
			.doc(`${uid}`)
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setChoice({
						weekTwoChoice: data.weekTwoChoice,
					});
				}
			})
			.catch(err => {
				console.log("Error retrieving data:", err);
			});
		db.collection("assignments")
			.doc("week2")
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setData({
						weeklyQuestion: data.weeklyQuestion,
						choice1: data.choice1,
						choice2: data.choice2,
						choice3: data.choice3,
						choice4: data.choice4,
					});
					// if (choice.weekTwoChoice === "choice1") {
					//   setResponse(data.response1);
					// } else if (choice.weekTwoChoice === "choice2") {
					//   setResponse(data.response2);
					// } else if (choice.weekTwoChoice === "choice3") {
					//   setResponse(data.response3);
					// } else if (choice.weekTwoChoice === "choice4") {
					//   setResponse(data.response4);
					// }
				}
			})
			.catch(err => {
				console.log("Error retrieving data:", err);
			});
	}, [data.weekTwoChoice, uid]);

	const handleInputChange = ({ target }) =>
		setChoice({
			[target.name]: target.value,
		});

	const submitResponse = e => {
		e.preventDefault();
		db.collection("users")
			.doc(`${uid}`)
			.update({
				weekTwoChoice: choice.weekTwoChoice,
			})
			.then(() => {
				navigate("/workspace/assignments/week-two-response");
			});
	};

	return (
		<>
			<div className="container text-center">
				<div className="row justify-content-center">
					{/* <div className="col-10 col-md-10 col-lg-8 col-xl-7 home-jl"> */}
					<div className="col-12">
						<h1 className="display-4 text-primary mt-3 mb-2">
							{/* Week Two Question */}
						</h1>
						<h5 className="weekly-question">{data.weeklyQuestion}</h5>
						<form className="responses">
							{data.choice1 !== "" ? (
								<span>
									<input
										type="radio"
										name="weekTwoChoice"
										value="choice1"
										checked={choice.weekTwoChoice === "choice1"}
										onChange={handleInputChange}
									/>{" "}
									1. {data.choice1}
								</span>
							) : (
								""
							)}
							{data.choice2 !== "" ? (
								<span>
									<input
										type="radio"
										name="weekTwoChoice"
										value="choice2"
										checked={choice.weekTwoChoice === "choice2"}
										onChange={handleInputChange}
									/>{" "}
									2. {data.choice2}
								</span>
							) : (
								""
							)}
							{data.choice3 !== "" ? (
								<span>
									<input
										type="radio"
										name="weekTwoChoice"
										value="choice3"
										checked={choice.weekTwoChoice === "choice3"}
										onChange={handleInputChange}
									/>{" "}
									3. {data.choice3}
								</span>
							) : (
								""
							)}
							{data.choice4 !== "" ? (
								<span>
									<input
										type="radio"
										name="weekTwoChoice"
										value="choice4"
										checked={choice.weekTwoChoice === "choice4"}
										onChange={handleInputChange}
									/>{" "}
									4. {data.choice4}
								</span>
							) : (
								""
							)}
							<button
								type="submit"
								name="mark-completed"
								onClick={submitResponse}
								disabled={choice.weekTwoChoice === undefined}
							>
								Submit
							</button>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Week2Question;
