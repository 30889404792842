import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router";
import { db } from "../Firebase";

const Week4Response = props => {
	const { user } = props;
	let uid;
	if (user != null) {
		uid = user.uid;
	}
	const [weekThreeChoice, setWeekThreeChoice] = useState("");
	const [video, setVideo] = useState({
		videoR1V1: "",
		videoR1V2: "",
		videoR2V1: "",
		videoR2V2: "",
		videoR3V1: "",
		videoR3V2: "",
		videoR4V1: "",
		videoR4V2: "",
	});
	// eslint-disable-next-line no-unused-vars
	const [response, setResponse] = useState("");

	useEffect(() => {
		db.collection("users")
			.doc(`${uid}`)
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setWeekThreeChoice(data.weekThreeChoice);
				}
			})
			.catch(err => {
				console.log("Error retrieving data:", err);
			});

		db.collection("assignments")
			.doc("week3")
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					// console.log(data);
					setVideo({
						videoR1V1: data.videoR1V1,
						videoR1V2: data.videoR1V2,
						videoR2V1: data.videoR2V1,
						videoR2V2: data.videoR2V2,
						videoR3V1: data.videoR3V1,
						videoR3V2: data.videoR3V2,
						videoR4V1: data.videoR4V1,
						videoR4V2: data.videoR4V2,
					});
					if (weekThreeChoice === "choice1") {
						setResponse(data.response1);
					} else if (weekThreeChoice === "choice2") {
						setResponse(data.response2);
					} else if (weekThreeChoice === "choice3") {
						setResponse(data.response3);
					} else if (weekThreeChoice === "choice4") {
						setResponse(data.response4);
					}
				}
			})
			.catch(err => {
				console.log("Error retrieving data:", err);
			});
	}, [weekThreeChoice, uid]);

	const goBack = e => {
		navigate("/workspace/assignments/week-three-question");
	};

	return (
		<>
			<div className="container text-center">
				<div className="row justify-content-center">
					{/* <div className="col-10 col-md-10 col-lg-8 col-xl-7 home-jl"> */}
					<div className="col-12">
						<h1 className="display-4 text-primary mt-3 mb-2">
							{/* Week Three Response */}
						</h1>
						{weekThreeChoice === "choice1" ? (
							<>
								{response === "" ? (
									""
								) : (
									<p className="db-textarea">{response}</p>
								)}
								{video.videoR1V1 === "" ? (
									""
								) : (
									<section className="video-container">
										<div
											style={{
												padding: "56.25% 0 0 0",
												position: "relative",
											}}
										>
											<iframe
												title="video"
												src={`https://player.vimeo.com/video/${video.videoR1V1}`}
												style={{
													position: "absolute",
													top: "0",
													left: "0",
													width: "100%",
													height: "100%",
												}}
												frameBorder="0"
												allow="autoplay; fullscreen"
												allowFullScreen
											></iframe>
										</div>
									</section>
								)}
								{video.videoR1V2 === "" ? (
									""
								) : (
									<section className="video-container">
										<div
											style={{ padding: "56.25% 0 0 0", position: "relative" }}
										>
											<iframe
												title="video"
												src={`https://player.vimeo.com/video/${video.videoR1V2}`}
												style={{
													position: "absolute",
													top: "0",
													left: "0",
													width: "100%",
													height: "100%",
												}}
												frameBorder="0"
												allow="autoplay; fullscreen"
												allowFullScreen
											></iframe>
										</div>
									</section>
								)}
							</>
						) : (
							""
						)}
						{weekThreeChoice === "choice2" ? (
							<>
								{response === "" ? (
									""
								) : (
									<p className="db-textarea">{response}</p>
								)}
								{video.videoR2V1 === "" ? (
									""
								) : (
									<section className="video-container">
										<div
											style={{ padding: "56.25% 0 0 0", position: "relative" }}
										>
											<iframe
												title="video"
												src={`https://player.vimeo.com/video/${video.videoR2V1}`}
												style={{
													position: "absolute",
													top: "0",
													left: "0",
													width: "100%",
													height: "100%",
												}}
												frameBorder="0"
												allow="autoplay; fullscreen"
												allowFullScreen
											></iframe>
										</div>
									</section>
								)}
								{video.videoR2V2 === "" ? (
									""
								) : (
									<section className="video-container">
										<div
											style={{ padding: "56.25% 0 0 0", position: "relative" }}
										>
											<iframe
												title="video"
												src={`https://player.vimeo.com/video/${video.videoR2V2}`}
												style={{
													position: "absolute",
													top: "0",
													left: "0",
													width: "100%",
													height: "100%",
												}}
												frameBorder="0"
												allow="autoplay; fullscreen"
												allowFullScreen
											></iframe>
										</div>
									</section>
								)}
							</>
						) : (
							""
						)}
						{weekThreeChoice === "choice3" ? (
							<>
								{response === "" ? (
									""
								) : (
									<p className="db-textarea">{response}</p>
								)}
								{video.videoR3V1 === "" ? (
									""
								) : (
									<section className="video-container">
										<div
											style={{ padding: "56.25% 0 0 0", position: "relative" }}
										>
											<iframe
												title="video"
												src={`https://player.vimeo.com/video/${video.videoR3V1}`}
												style={{
													position: "absolute",
													top: "0",
													left: "0",
													width: "100%",
													height: "100%",
												}}
												frameBorder="0"
												allow="autoplay; fullscreen"
												allowFullScreen
											></iframe>
										</div>
									</section>
								)}
								{video.videoR3V2 === "" ? (
									""
								) : (
									<section className="video-container">
										<div
											style={{ padding: "56.25% 0 0 0", position: "relative" }}
										>
											<iframe
												title="video"
												src={`https://player.vimeo.com/video/${video.videoR3V2}`}
												style={{
													position: "absolute",
													top: "0",
													left: "0",
													width: "100%",
													height: "100%",
												}}
												frameBorder="0"
												allow="autoplay; fullscreen"
												allowFullScreen
											></iframe>
										</div>
									</section>
								)}
							</>
						) : (
							""
						)}
						{weekThreeChoice === "choice4" ? (
							<>
								{response === "" ? (
									""
								) : (
									<p className="db-textarea">{response}</p>
								)}
								{video.videoR4V1 === "" ? (
									""
								) : (
									<section className="video-container">
										<div
											style={{ padding: "56.25% 0 0 0", position: "relative" }}
										>
											<iframe
												title="video"
												src={`https://player.vimeo.com/video/${video.videoR4V1}`}
												style={{
													position: "absolute",
													top: "0",
													left: "0",
													width: "100%",
													height: "100%",
												}}
												frameBorder="0"
												allow="autoplay; fullscreen"
												allowFullScreen
											></iframe>
										</div>
									</section>
								)}
								{video.videoR4V2 === "" ? (
									""
								) : (
									<section className="video-container">
										<div
											style={{ padding: "56.25% 0 0 0", position: "relative" }}
										>
											<iframe
												title="video"
												src={`https://player.vimeo.com/video/${video.videoR4V2}`}
												style={{
													position: "absolute",
													top: "0",
													left: "0",
													width: "100%",
													height: "100%",
												}}
												frameBorder="0"
												allow="autoplay; fullscreen"
												allowFullScreen
											></iframe>
										</div>
									</section>
								)}
							</>
						) : (
							""
						)}
						<button
							type="submit"
							name="change-choice"
							onClick={goBack}
							className="change-choice"
						>
							Back
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default Week4Response;
