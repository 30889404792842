import React, { useState, useEffect } from "react";
import { db } from "../../Firebase";

const EditAssignmentOne = () => {
	const [item, setItem] = useState({
		isActive: "",
		weekNum: "",
		assignmentTitle: "",
		readings: "",
		synopsis1: "",
		question1: "",
		question2: "",
		question3: "",
		question4: "",
		response1: "",
	});

	useEffect(item => {
		db.collection("assignments")
			.doc("week1")
			.get()
			.then(doc => {
				if (doc.exists) {
					let data = doc.data();
					setItem({
						isActive: data.isActive,
						weekNum: data.weekNum,
						assignmentTitle: data.assignmentTitle,
						readings: data.readings || "",
						synopsis1: data.synopsis1,
						question1: data.question1,
						question2: data.question2,
						question3: data.question3,
						question4: data.question4,
						response1: data.response1,
					});
				}
			})
			.catch(function (error) {
				console.log("Error getting documents: ", error);
			});
	}, []);

	const msg = document.querySelector(".message");

	const submitEditAssignment = e => {
		e.preventDefault();
		db.collection("assignments")
			.doc("week1")
			.set({
				isActive: item.isActive,
				weekNum: item.weekNum,
				assignmentTitle: item.assignmentTitle,
				readings: item.readings || "",
				synopsis1: item.synopsis1,
				question1: item.question1,
				question2: item.question2,
				question3: item.question3,
				question4: item.question4,
				response1: item.response1,
			})
			.then(function () {
				msg.classList.add("fade-out");
				msg.innerText = `Assignment ${item.weekNum} successfully updated!`;
				setTimeout(() => {
					msg.classList.remove("fade-out");
					msg.innerText = "";
				}, 1000);
			})
			.catch(function (error) {
				console.error("Error writing document: ", error);
			});
	};

	const handleInputChange = e => {
		setItem({
			...item,
			[e.target.name]: e.target.value,
		});
	};

	return (
		<>
			<div className="container text-center">
				<div className="row justify-content-center">
					{/* <div className="col-10 col-md-10 col-lg-8 col-xl-10"> */}
					<div className="col-12">
						<h1 className="display-4 text-primary mt-3 mb-2">
							Edit Assignment {item.weekNum}
						</h1>
						<form className="admin-actions" onSubmit={submitEditAssignment}>
							<label htmlFor="weekNum">Week Number</label>
							<input
								type="text"
								placeholder="Week number"
								id="weekNum"
								name="weekNum"
								value={item.weekNum}
								readOnly
								required
							/>
							<label htmlFor="assignmentTitle">Topic</label>
							<input
								type="text"
								placeholder="Assignment Title"
								id="assignmentTitle"
								name="assignmentTitle"
								value={item.assignmentTitle}
								onChange={handleInputChange}
								required
							/>
							<label htmlFor="readings">Readings</label>
							<textarea
								rows="6"
								cols="50"
								placeholder="Readings "
								id="readings"
								name="readings"
								value={item.readings}
								onChange={handleInputChange}
							/>
							<br />
							<label htmlFor="synopsis1">Scenario</label>
							<textarea
								rows="20"
								cols="50"
								placeholder="Scenario"
								id="synopsis1"
								name="synopsis1"
								value={item.synopsis1}
								onChange={handleInputChange}
								required
							/>
							<br />
							<h4>Questions:</h4>
							{item.question1 !== "" ? (
								<div className="questions">
									<span style={{ marginRight: "10px" }}>1.</span>
									<input
										type="text"
										name="question1"
										id="question1"
										defaultValue={item.question1}
										onChange={handleInputChange}
									/>
								</div>
							) : (
								""
							)}
							{item.question2 !== "" ? (
								<div className="questions">
									<span style={{ marginRight: "10px" }}>2.</span>
									<input
										type="text"
										name="question2"
										id="question2"
										defaultValue={item.question2}
										onChange={handleInputChange}
									/>
								</div>
							) : (
								""
							)}
							{item.question3 !== "" ? (
								<div className="questions">
									<span style={{ marginRight: "10px" }}>3.</span>
									<input
										type="text"
										name="question3"
										id="question3"
										defaultValue={item.question3}
										onChange={handleInputChange}
									/>
								</div>
							) : (
								""
							)}
							{item.question4 !== "" ? (
								<div className="questions">
									<span style={{ marginRight: "10px" }}>4.</span>
									<input
										type="text"
										name="question4"
										id="question4"
										value={item.question4}
										onChange={handleInputChange}
									/>
								</div>
							) : (
								""
							)}
							<h4 style={{ marginTop: "40px" }}>Letter of Appointment</h4>
							<textarea
								rows="14"
								cols="50"
								placeholder="Response 1"
								id="response1"
								name="response1"
								value={item.response1}
								onChange={handleInputChange}
							/>
							<p className="message"></p>
							<button type="submit" name="update-assignment">
								Update assignment
							</button>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditAssignmentOne;
