import React, { useState } from "react";
import firebase from "./Firebase";
import FormError from "./FormError";
import { Link, navigate } from "@reach/router";
// import Logo from "../images/Ryerson-rgb.png";

function Login(props) {
  const { userName, admin } = props;
  // const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState(null);

  const handleSubmit = e => {
    // const registrationInfo = {
    //   email: email,
    //   password: password
    // };
    e.preventDefault();

    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(function() {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return firebase.auth().signInWithEmailAndPassword(email, password);
      })
      // firebase
      //   .auth()
      //   .signInWithEmailAndPassword(
      //     registrationInfo.email,
      //     registrationInfo.password
      //   )
      .then(() => {
        navigate("/workspace");
      })
      .catch(err => {
        if (err.message != null) {
          setErrMsg(err.message);
        } else {
          setErrMsg(null);
        }
      });
  };
  return (
    <>
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-10 col-md-10 col-lg-8 col-xl-7">
            {/* <h1 className="display-4 text-primary mt-3 mb-2">TERRack Soft</h1> */}
            {!userName ? (
              <>
                {/* <p>Welcome to the Terronto Games employee login portal.</p> */}
                {/* <p>Please enter your Email and Password to continue:</p> */}
                <form className="mt-3" onSubmit={handleSubmit}>
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className="card bg-light">
                          <div className="card-body">
                            <h3 className="font-weight-light mb-3">Log in</h3>
                            {errMsg !== null ? (
                              <FormError theMsg={errMsg} />
                            ) : null}
                            <section className="form-group">
                              <label
                                className="form-control-label sr-only"
                                htmlFor="email"
                              >
                                Email
                              </label>
                              <input
                                required
                                className="form-control"
                                type="email"
                                autoComplete="email"
                                id="email"
                                name="email"
                                placeholder="Email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                              />
                            </section>
                            <section className="form-group">
                              <label
                                className="form-control-label sr-only"
                                htmlFor="password"
                              >
                                Password
                              </label>
                              <input
                                required
                                className="form-control"
                                type="password"
                                autoComplete="current-password"
                                id="password"
                                name="password"
                                placeholder="Password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                              />
                            </section>
                            <div className="form-group text-right mb-0">
                              <button className="btn btn-primary" type="submit">
                                Log in
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                {admin && (
                  <p>
                    <Link className="nav-item nav-link" to="/register">
                      Or create a New Account
                    </Link>
                  </p>
                )}
              </>
            ) : (
              <p style={{ color: "red", fontWeight: "bold" }}>
                You are logged in!
              </p>
            )}
            {/* <p className="font-weight-bold">
              NOTE: This is a simulated environment for use by students from
              Ryerson University.
            </p>
            <span className="page-logo">
              <img src={Logo} alt="Ryerson logo" />
            </span> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
