import React, { useState } from "react";
import { db } from "../Firebase";

function CreateAssignment() {
  const [item, setItem] = useState({
    isActive: false,
    weekNum: "",
    assignmentTitle: "",
    readings: "",
    synopsis1: "",
    q1: false,
    question1: "",
    q2: false,
    question2: "",
    q3: false,
    question3: "",
    q4: false,
    question4: "",
    response1: "",
    response2: "",
    response3: "",
    response4: ""
  });

  const msg = document.querySelector(".message");

  const submitCreateAssignment = e => {
    e.preventDefault();
    // Add a new document in the "assignments" collection
    db.collection("assignments")
      .doc(`week${item.weekNum}`)
      .set({
        isActive: false,
        weekNum: item.weekNum,
        assignmentTitle: item.assignmentTitle,
        readings: item.readings || "",
        question1: item.question1,
        question2: item.question2,
        question3: item.question3,
        question4: item.question4,
        response1: item.response1,
        response2: item.response2,
        response3: item.response3,
        response4: item.response4
      })
      .then(function() {
        // reset form
        setItem({
          weekNum: "",
          assignmentTitle: "",
          readings: "",
          q1: false,
          question1: "",
          q2: false,
          question2: "",
          q3: false,
          question3: "",
          q4: false,
          question4: "",
          reponse1: ""
        });
        msg.innerHTML = "Assignment successfully created.";
        console.log("Document successfully written!");
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      });
  };

  const handleInputChange = ({ target }) =>
    setItem({
      ...item,
      [target.name]: target.value
    });

  const handleCheckbox = e => {
    setItem({
      ...item,
      [e.target.name]: e.target.checked
    });
  };

  const toggleModal = e => {
    const modal = document.querySelector(".message-modal");
    modal.classList.add("closed");
  };

  return (
    <>
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-10 col-md-10 col-lg-8 col-xl-10">
            <h1 className="display-4 text-primary mt-3 mb-2">
              Create an Assignment
            </h1>
            <form className="admin-actions" onSubmit={submitCreateAssignment}>
              <label htmlFor="weekNum">Week Number</label>
              <input
                type="text"
                placeholder="Week number"
                id="weekNum"
                name="weekNum"
                value={item.weekNum}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="assignmentTitle">Topic</label>
              <input
                type="text"
                placeholder="Assignment Title"
                id="assignmentTitle"
                name="assignmentTitle"
                onChange={handleInputChange}
                required
              />
              <label htmlFor="readings">Readings</label>
              <textarea
                rows="6"
                cols="50"
                wrap="hard"
                placeholder="Readings"
                id="readings"
                name="readings"
                value={item.readings}
                onChange={handleInputChange}
              />
              <br />
              <label htmlFor="synopsis1">Scenario</label>
              <textarea
                rows="20"
                cols="50"
                placeholder="Scenario"
                id="synopsis1"
                name="synopsis1"
                onChange={handleInputChange}
                required
              />
              <br />
              <h4>Questions:</h4>
              <div className="questions">
                <input
                  type="checkbox"
                  name="q1"
                  id="q1"
                  checked={item.q1}
                  onChange={handleCheckbox}
                />
                <input
                  type="text"
                  name="question1"
                  id="question1"
                  placeholder="Question 1"
                  disabled={!item.q1}
                  onChange={handleInputChange}
                />
              </div>
              <textarea
                rows="14"
                cols="50"
                placeholder="Response 1"
                id="response1"
                name="response1"
                disabled={!item.q1}
                onChange={handleInputChange}
                required
              />
              <div className="questions">
                <input
                  type="checkbox"
                  name="q2"
                  id="q2"
                  checked={item.q2}
                  onChange={handleCheckbox}
                />
                <input
                  type="text"
                  name="question2"
                  id="question2"
                  placeholder="Question 2"
                  disabled={!item.q2}
                  onChange={handleInputChange}
                />
              </div>
              <textarea
                rows="14"
                cols="50"
                placeholder="Response 2"
                id="response2"
                name="response2"
                disabled={!item.q2}
                onChange={handleInputChange}
                required
              />
              <div className="questions">
                <input
                  type="checkbox"
                  name="q3"
                  id="q3"
                  checked={item.q3}
                  onChange={handleCheckbox}
                />
                <input
                  type="text"
                  name="question3"
                  id="question3"
                  placeholder="Question 3"
                  disabled={!item.q3}
                  onChange={handleInputChange}
                />
              </div>
              <textarea
                rows="14"
                cols="50"
                placeholder="Response 3"
                id="response3"
                name="response3"
                disabled={!item.q3}
                onChange={handleInputChange}
                required
              />
              <div className="questions">
                <input
                  type="checkbox"
                  name="q4"
                  id="q4"
                  placeholder="Question 4"
                  checked={item.q4}
                  onChange={handleCheckbox}
                />
                <input
                  type="text"
                  name="question4"
                  id="question4"
                  disabled={!item.q4}
                  onChange={handleInputChange}
                />
              </div>
              <textarea
                rows="14"
                cols="50"
                placeholder="Response 4"
                id="response4"
                name="response4"
                disabled={!item.q4}
                onChange={handleInputChange}
                required
              />
              <button type="submit" name="create-assignment">
                Create assignment
              </button>
            </form>
            <br />
            <div className="message-modal" onClick={toggleModal}>
              <p className="message"></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateAssignment;
