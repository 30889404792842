import React from "react";

const Team = () => {
	return (
		<>
			<div className="container text-center">
				<div className="row justify-content-center">
					{/* <div className="col-10 col-md-10 col-lg-12 col-xl-12"> */}
					<div className="col-12">
						<h1 className="display-4 text-primary mt-3 mb-2">Team</h1>
						<div className="team-members">
							<section className="team-member">
								<h4 className="team-title">Sydney</h4>
								<div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
									<iframe
										title="Sydney"
										src="https://player.vimeo.com/video/384091768"
										style={{
											position: "absolute",
											top: "0",
											left: "0",
											width: "100%",
											height: "100%",
										}}
										frameBorder="0"
										allow="autoplay; fullscreen"
										allowFullScreen
									></iframe>
								</div>
								<script src="https://player.vimeo.com/api/player.js"></script>
							</section>
							<section className="team-member">
								<h4 className="team-title">Rick</h4>
								<div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
									<iframe
										title="Rick"
										src="https://player.vimeo.com/video/384091708"
										style={{
											position: "absolute",
											top: "0",
											left: "0",
											width: "100%",
											height: "100%",
										}}
										frameBorder="0"
										allow="autoplay; fullscreen"
										allowFullScreen
									></iframe>
								</div>
								<script src="https://player.vimeo.com/api/player.js"></script>
							</section>
							<section className="team-member">
								<h4 className="team-title">Emily</h4>
								<div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
									<iframe
										title="Emily"
										src="https://player.vimeo.com/video/384091632"
										style={{
											position: "absolute",
											top: "0",
											left: "0",
											width: "100%",
											height: "100%",
										}}
										frameBorder="0"
										allow="autoplay; fullscreen"
										allowFullScreen
									></iframe>
								</div>
								<script src="https://player.vimeo.com/api/player.js"></script>
							</section>
							<section className="team-member">
								<h4 className="team-title">Mathew</h4>
								<div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
									<iframe
										title="Mathew"
										src="https://player.vimeo.com/video/384091520"
										style={{
											position: "absolute",
											top: "0",
											left: "0",
											width: "100%",
											height: "100%",
										}}
										frameBorder="0"
										allow="autoplay; fullscreen"
										allowFullScreen
									></iframe>
								</div>
								<script src="https://player.vimeo.com/api/player.js"></script>
							</section>
							<section className="team-member">
								<h4 className="team-title">Julian</h4>
								<div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
									<iframe
										title="Julian"
										src="https://player.vimeo.com/video/384091451"
										style={{
											position: "absolute",
											top: "0",
											left: "0",
											width: "100%",
											height: "100%",
										}}
										frameBorder="0"
										allow="autoplay; fullscreen"
										allowFullScreen
									></iframe>
								</div>
								<script src="https://player.vimeo.com/api/player.js"></script>
							</section>
							<section className="team-member">
								<h4 className="team-title">Jonah</h4>
								<div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
									<iframe
										title="Jonah"
										src="https://player.vimeo.com/video/384091249"
										style={{
											position: "absolute",
											top: "0",
											left: "0",
											width: "100%",
											height: "100%",
										}}
										frameBorder="0"
										allow="autoplay; fullscreen"
										allowFullScreen
									></iframe>
								</div>
								<script src="https://player.vimeo.com/api/player.js"></script>
							</section>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Team;
