import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router";
import firebase from "../Firebase";
import { db } from "../Firebase";

const Week2 = props => {
	const { user } = props;
	let uid;
	if (user != null) {
		uid = user.uid;
	}

	const [elements, setElements] = useState({
		isActive: "",
		weekNum: "",
		assignmentTitle: "",
		readings: "",
		synopsis1: "",
		weeklyVideo1: "",
		weeklyVideo2: "",
		instructions: "",
	});
	const [response, setResponse] = useState({
		weekTwoChoice: "",
		weekTwoCompleted: "",
		weekTwoStudentResponse: "",
	});

	useEffect(() => {
		db.collection("assignments")
			.doc("week2")
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					// console.log(data);
					setElements({
						weekNum: data.weekNum,
						assignmentTitle: data.assignmentTitle,
						readings: data.readings,
						synopsis1: data.synopsis1,
						weeklyVideo1: data.weeklyVideo1,
						weeklyVideo2: data.weeklyVideo2,
						instructions: data.instructions,
					});
				} else {
					console.log("No such document");
				}
			})
			.catch(err => {
				console.log("Error retrieving data:", err);
			});

		db.collection("users")
			.doc(`${uid}`)
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setResponse({
						weekTwoCompleted: data.weekTwoCompleted,
						weekTwoStudentResponse: data.weekTwoStudentResponse,
					});
				} else {
					// console.log("No such document");
				}
			})
			.catch(err => {
				console.log("Error retrieving data:", err);
			});
	}, [uid]);

	const handleInputChange = ({ target }) =>
		setResponse({
			...response,
			[target.name]: target.value,
		});

	const submitResponse = e => {
		e.preventDefault();
		const userRef = db.collection("users").doc(`${uid}`);
		return userRef
			.update({
				weekTwoCompletedAt: firebase.firestore.FieldValue.serverTimestamp(),
				weekTwoCompleted: true,
				weekTwoStudentResponse: response.weekTwoStudentResponse,
			})
			.then(() => {
				navigate("/workspace/assignments/week-two-question");
			});
	};

	const continueToQuestions = e => {
		// console.log("continue");
		navigate("/workspace/assignments/week-two-question");
	};

	return (
		<>
			<div className="container text-center">
				<div className="row justify-content-center">
					{/* <div className="col-10 col-md-10 col-lg-8 col-xl-7 home-jl"> */}
					<div className="col-12">
						<h1 className="display-4 text-primary mt-3 mb-2">Week Two</h1>
						<h3 className="assignment-header">Topic:</h3>
						<p>{elements.assignmentTitle}</p>
						<h3 className="assignment-header">Readings:</h3>
						{elements.readings !== "" ? (
							<p className="db-textarea">{elements.readings}</p>
						) : (
							""
						)}
						<br />
						<h3 className="assignment-header">Scenario:</h3>
						<p className="db-textarea">{elements.synopsis1}</p>
						{elements.weeklyVideo1 === "" ? (
							""
						) : (
							<section className="video-container">
								<div
									style={{
										padding: "56.25% 0 0 0",
										position: "relative",
									}}
								>
									<iframe
										title="video"
										src={`https://player.vimeo.com/video/${elements.weeklyVideo1}`}
										style={{
											position: "absolute",
											top: "0",
											left: "0",
											width: "100%",
											height: "100%",
										}}
										frameBorder="0"
										allow="autoplay; fullscreen"
										allowFullScreen
									></iframe>
								</div>
							</section>
						)}
						{elements.weeklyVideo2 === "" ? (
							""
						) : (
							<section className="video-container">
								<div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
									<iframe
										title="video"
										src={`https://player.vimeo.com/video/${elements.weeklyVideo2}`}
										style={{
											position: "absolute",
											top: "0",
											left: "0",
											width: "100%",
											height: "100%",
										}}
										frameBorder="0"
										allow="autoplay; fullscreen"
										allowFullScreen
									></iframe>
								</div>
							</section>
						)}
						<form className="responses" onSubmit={submitResponse}>
							<p className="instructions">{elements.instructions}</p>
							<>
								<textarea
									rows="7"
									cols="80"
									placeholder="Type your response here"
									id="weekTwoStudentResponse"
									name="weekTwoStudentResponse"
									className="fluid"
									value={response.weekTwoStudentResponse}
									disabled={response.weekTwoCompleted}
									onChange={handleInputChange}
									required
								/>
								<div className="btn-group">
									<button
										type="submit"
										name="submit-response"
										disabled={response.weekTwoCompleted}
									>
										Submit
									</button>
									{response.weekTwoCompleted ? (
										<>
											<span className="submit-msg submitted">
												You have already submitted a response for this week.
											</span>
											<button
												name="continue-to-questions"
												onClick={continueToQuestions}
											>
												Explore Further
											</button>
										</>
									) : (
										<span className="submit-msg">
											Note: You will not be able to edit your response once it
											has been submitted.
										</span>
									)}
								</div>
							</>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Week2;
