import React from "react";
// import { Link } from "@reach/router";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import logo from "../images/TG-Logo-white.png";
import logoLoggedin from "../images/TG-Logo-white-red.png";

import "bootstrap/dist/css/bootstrap.min.css";

const Navigation = props => {
  const { user, logOutUser, admin } = props;
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Navbar.Brand href="/">
        {!user && <img src={logo} alt="Terronto Games Logo" className="logo" />}
        {user && (
          <img src={logoLoggedin} alt="Terronto Games Logo" className="logo" />
        )}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          {!user && <Nav.Link href="/login">Log In</Nav.Link>}
          {user && (
            <>
              <Nav.Link href="/about">About</Nav.Link>
              <NavDropdown.Divider className="main" />
              <Nav.Link href="/workspace">Workspace</Nav.Link>
              <NavDropdown.Divider className="main" />
              {/* <Nav.Link href="/memos">Memos</Nav.Link> */}
              <Nav.Link href="/team">Team</Nav.Link>
              <NavDropdown.Divider className="main" />
              <Nav.Link href="/directory">Directory</Nav.Link>
              <NavDropdown.Divider className="main" />
              <Nav.Link href="/profile">Profile</Nav.Link>
              <NavDropdown.Divider className="main" />
            </>
          )}
          {admin && (
            <>
              {/* <Nav.Link href="/admin/assignments-list">Admin</Nav.Link> */}
              <NavDropdown title="Admin" id="collasible-nav-dropdown">
                <NavDropdown.Divider />
                <NavDropdown.Item href="/admin/assignments-list">
                  Assignments List
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/admin/students-list">
                  Students List
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/register">Register</NavDropdown.Item>
                <NavDropdown.Divider />
              </NavDropdown>
              <NavDropdown.Divider className="main" />
            </>
          )}
          {user && (
            <Nav.Link href="/login" onClick={e => logOutUser(e)}>
              Logout
            </Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
