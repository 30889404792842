import React, { useState, useEffect } from "react";
import { db } from "../../Firebase";

const EditAssignmentEleven = () => {
	const [item, setItem] = useState({
		isActive: "",
		weekNum: "",
		assignmentTitle: "",
		readings: "",
		synopsis1: "",
		weeklyVideo1: "",
		weeklyVideo2: "",
		instuctions: "",
		weeklyQuestion: "",
		choice1: "",
		choice2: "",
		choice3: "",
		choice4: "",
		response1: "",
		response2: "",
		response3: "",
		response4: "",
		videoR1V1: "",
		videoR1V2: "",
		videoR2V1: "",
		videoR2V2: "",
		videoR3V1: "",
		videoR3V2: "",
		videoR4V1: "",
		videoR4V2: "",
	});

	useEffect(item => {
		db.collection("assignments")
			.doc("week11")
			.get()
			.then(doc => {
				if (doc.exists) {
					let data = doc.data();
					setItem({
						isActive: data.isActive,
						weekNum: data.weekNum,
						assignmentTitle: data.assignmentTitle,
						readings: data.readings || "",
						synopsis1: data.synopsis1 || "",
						weeklyVideo1: data.weeklyVideo1 || "",
						weeklyVideo2: data.weeklyVideo2 || "",
						instructions: data.instructions || "",
						weeklyQuestion: data.weeklyQuestion || "",
						choice1: data.choice1 || "",
						choice2: data.choice2 || "",
						choice3: data.choice3 || "",
						choice4: data.choice4 || "",
						response1: data.response1 || "",
						response2: data.response2 || "",
						response3: data.response3 || "",
						response4: data.response4 || "",
						videoR1V1: data.videoR1V1 || "",
						videoR1V2: data.videoR1V2 || "",
						videoR2V1: data.videoR2V1 || "",
						videoR2V2: data.videoR2V2 || "",
						videoR3V1: data.videoR3V1 || "",
						videoR3V2: data.videoR3V2 || "",
						videoR4V1: data.videoR4V1 || "",
						videoR4V2: data.videoR4V2 || "",
					});
				}
			})
			.catch(function (error) {
				console.log("Error getting documents: ", error);
			});
	}, []);

	const msg = document.querySelector(".message");

	const submitEditAssignment = e => {
		e.preventDefault();
		db.collection("assignments")
			.doc("week11")
			.set({
				isActive: item.isActive,
				weekNum: item.weekNum,
				assignmentTitle: item.assignmentTitle,
				readings: item.readings || "",
				synopsis1: item.synopsis1 || "",
				weeklyVideo1: item.weeklyVideo1 || "",
				weeklyVideo2: item.weeklyVideo2 || "",
				instructions: item.instructions || "",
				weeklyQuestion: item.weeklyQuestion || "",
				choice1: item.choice1 || "",
				choice2: item.choice2 || "",
				choice3: item.choice3 || "",
				choice4: item.choice4 || "",
				response1: item.response1 || "",
				response2: item.response2 || "",
				response3: item.response3 || "",
				response4: item.response4 || "",
				videoR1V1: item.videoR1V1 || "",
				videoR1V2: item.videoR1V2 || "",
				videoR2V1: item.videoR2V1 || "",
				videoR2V2: item.videoR2V2 || "",
				videoR3V1: item.videoR3V1 || "",
				videoR3V2: item.videoR3V2 || "",
				videoR4V1: item.videoR4V1 || "",
				videoR4V2: item.videoR4V2 || "",
			})
			.then(function () {
				msg.innerHTML = "Assignment successfully updated.";
			})
			.catch(function (error) {
				console.error("Error writing document: ", error);
			});
	};

	const handleInputChange = e => {
		setItem({
			...item,
			[e.target.name]: e.target.value,
		});
	};

	const toggleModal = e => {
		const modal = document.querySelector(".message-modal");
		modal.classList.add("closed");
	};

	return (
		<>
			<div className="container text-center">
				<div className="row justify-content-center">
					{/* <div className="col-10 col-md-10 col-lg-8 col-xl-10"> */}
					<div className="col-12">
						<h1 className="display-4 text-primary mt-3 mb-2">
							Edit Assignment {item.weekNum}
						</h1>
						<form className="admin-actions" onSubmit={submitEditAssignment}>
							<label htmlFor="weekNum">Week Number</label>
							<input
								type="text"
								placeholder="Week number"
								id="weekNum"
								name="weekNum"
								value={item.weekNum}
								readOnly
								required
							/>
							<label htmlFor="assignmentTitle">Topic</label>
							<input
								type="text"
								placeholder="Assignment Title"
								id="assignmentTitle"
								name="assignmentTitle"
								value={item.assignmentTitle}
								onChange={handleInputChange}
								required
							/>
							<label htmlFor="readings">Readings</label>
							<textarea
								rows="6"
								cols="50"
								placeholder="Readings"
								id="readings"
								name="readings"
								value={item.readings}
								onChange={handleInputChange}
								required
							/>
							<br />
							<label htmlFor="synopsis1">Scenario</label>
							<textarea
								rows="20"
								cols="50"
								placeholder="Synopsis"
								id="synopsis1"
								name="synopsis1"
								value={item.synopsis1}
								onChange={handleInputChange}
								required
							/>
							<br />
							<div className="video-inputs">
								<label htmlFor="weeklyVideo1">Weekly Video 1</label>
								<input
									type="text"
									name="weeklyVideo1"
									id="weeklyVideo1"
									placeholder="Weekly Video One"
									value={item.weeklyVideo1}
									onChange={handleInputChange}
								/>
								<label htmlFor="weeklyVideo2">Weekly Video 2</label>
								<input
									type="text"
									name="weeklyVideo2"
									id="weeklyVideo2"
									placeholder="Weekly Video Two"
									value={item.weeklyVideo2}
									onChange={handleInputChange}
								/>
							</div>
							<br />
							<label htmlFor="instructions">Instructions</label>
							<textarea
								rows="6"
								cols="50"
								placeholder="Instructions"
								id="instructions"
								name="instructions"
								value={item.instructions}
								onChange={handleInputChange}
								required
							/>
							<label htmlFor="weeklyQuestion">Weekly Question</label>
							<input
								type="text"
								placeholder="Weekly Question"
								id="weeklyQuestion"
								name="weeklyQuestion"
								value={item.weeklyQuestion}
								onChange={handleInputChange}
								required
							/>
							<br />
							<h4>Choices:</h4>
							<div className="questions">
								<span className="choice-number">C1.</span>
								<input
									type="text"
									name="choice1"
									id="choice1"
									value={item.choice1}
									onChange={handleInputChange}
								/>
							</div>
							<textarea
								rows="8"
								cols="50"
								placeholder="Text Response 1"
								id="response1"
								name="response1"
								value={item.response1}
								onChange={handleInputChange}
							/>
							<div className="video-inputs">
								<label htmlFor="videoR1V1">Video Response 1</label>
								<input
									type="text"
									name="videoR1V1"
									id="videoR1V1"
									placeholder="Video Response One"
									value={item.videoR1V1}
									onChange={handleInputChange}
								/>
								<label htmlFor="videoR1V2">Video Response 2</label>
								<input
									type="text"
									name="videoR1V2"
									id="videoR1V2"
									placeholder="Video Response Two"
									value={item.videoR1V2}
									onChange={handleInputChange}
								/>
							</div>
							<div className="questions">
								<span className="choice-number">C2.</span>
								<input
									type="text"
									name="choice2"
									id="choice2"
									value={item.choice2}
									onChange={handleInputChange}
								/>
							</div>
							<textarea
								rows="8"
								cols="50"
								placeholder="Text Response 2"
								id="response2"
								name="response2"
								value={item.response2}
								onChange={handleInputChange}
							/>
							<div className="video-inputs">
								<label htmlFor="videoR2V1">Video Response 1</label>
								<input
									type="text"
									name="videoR2V1"
									id="videoR2V1"
									placeholder="Video Response One"
									value={item.videoR2V1}
									onChange={handleInputChange}
								/>
								<label htmlFor="videoR2V2">Video Response 2</label>
								<input
									type="text"
									name="videoR2V2"
									id="videoR2V2"
									placeholder="Video Response Two"
									value={item.videoR2V2}
									onChange={handleInputChange}
								/>
							</div>
							<>
								<div className="questions">
									<span className="choice-number">C3.</span>
									<input
										type="text"
										name="choice3"
										id="choice3"
										value={item.choice3}
										onChange={handleInputChange}
									/>
								</div>
								<textarea
									rows="8"
									cols="50"
									placeholder="Text Response 3"
									id="response3"
									name="response3"
									value={item.response3}
									onChange={handleInputChange}
								/>
							</>
							<div className="video-inputs">
								<label htmlFor="videoR3V1">Video Response 1</label>
								<input
									type="text"
									name="videoR3V1"
									id="videoR3V1"
									placeholder="Video Response One"
									value={item.videoR3V1}
									onChange={handleInputChange}
								/>
								<label htmlFor="videoR3V2">Video Response 2</label>
								<input
									type="text"
									name="videoR3V2"
									id="videoR3V2"
									placeholder="Video Response Two"
									value={item.videoR3V2}
									onChange={handleInputChange}
								/>
							</div>
							<>
								<div className="questions">
									<span className="choice-number">C4.</span>
									<input
										type="text"
										name="choice4"
										id="choice4"
										value={item.choice4}
										onChange={handleInputChange}
									/>
								</div>
								<textarea
									rows="8"
									cols="50"
									placeholder="Text Response 4"
									id="response4"
									name="response4"
									value={item.response4}
									onChange={handleInputChange}
								/>
								<div className="video-inputs">
									<label htmlFor="videoR4V1">Video Response 1</label>
									<input
										type="text"
										name="videoR4V1"
										id="videoR4V1"
										placeholder="Video Response One"
										value={item.videoR4V1}
										onChange={handleInputChange}
									/>
									<label htmlFor="videoR4V2">Video Response 2</label>
									<input
										type="text"
										name="videoR4V2"
										id="videoR4V2"
										placeholder="Video Response Two"
										value={item.videoR4V2}
										onChange={handleInputChange}
									/>
								</div>
							</>
							<p className="message"></p>
							<button type="submit" name="update-assignment">
								Update assignment
							</button>
						</form>
						<div className="message-modal" onClick={toggleModal}></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditAssignmentEleven;
