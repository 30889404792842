import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router";
import firebase, { db } from "../Firebase";

const Week1 = props => {
	const { user } = props;

	const [elements, setElements] = useState({
		isActive: "",
		weekNum: "",
		assignmentTitle: "",
		readings: "",
		synopsis1: "",
		question1: "",
		question2: "",
		question3: "",
		question4: "",
	});
	const [response, setResponse] = useState({
		response1: "",
	});

	useEffect(() => {
		const docRef = db.collection("assignments").doc("week1");

		docRef
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setElements({
						weekNum: data.weekNum,
						assignmentTitle: data.assignmentTitle,
						readings: data.readings,
						synopsis1: data.synopsis1,
						question1: data.question1,
						question2: data.question2,
						question3: data.question3,
						question4: data.question4,
					});
				} else {
					console.log("No such document");
				}
			})
			.catch(err => {
				console.log("Error retrieving data:", err);
			});
	}, []);

	const handleInputChange = ({ target }) =>
		setResponse({
			...response,
			[target.name]: target.value,
		});

	const submitResponse = e => {
		e.preventDefault();
		const userRef = db.collection("users").doc(user.uid);
		return userRef
			.update({
				weekOneResponse: response.response1,
				weekOneCompletedAt: firebase.firestore.FieldValue.serverTimestamp(),
				weekOneCompleted: true,
			})
			.then(() => {
				navigate("/workspace/assignments/week-one-response");
			});
	};

	return (
		<>
			<div className="container text-center">
				<div className="row justify-content-center">
					{/* <div className="col-10 col-md-10 col-lg-8 col-xl-7 home-jl"> */}
					<div className="col-12">
						<h1 className="display-4 text-primary mt-3 mb-2">Week One</h1>
						<h3 className="assignment-header">Topic:</h3>
						<p>{elements.assignmentTitle}</p>
						<h3 className="assignment-header">Readings:</h3>
						{elements.readings !== "" ? (
							<p className="db-textarea">{elements.readings}</p>
						) : (
							""
						)}
						<br />
						<h3 className="assignment-header">Scenario:</h3>
						<p className="db-textarea">{elements.synopsis1}</p>
						<br />
						<br />
						<h4 style={{ textAlign: "left" }}>Question:</h4>
						<div style={{ textAlign: "left" }}>
							{elements.question1 !== "" ? <p>1. {elements.question1}</p> : ""}
							{elements.question2 !== "" ? <p>2. {elements.question2}</p> : ""}
							{elements.question3 !== "" ? <p>3. {elements.question3}</p> : ""}
							{elements.question4 !== "" ? <p>4. {elements.question4}</p> : ""}
							<p>
								Please answer the above questions (approxiamately 250 words)
							</p>
						</div>
						<form className="admin-actions" onSubmit={submitResponse}>
							<textarea
								rows="7"
								cols="80"
								placeholder="Type your answers here"
								id="response1"
								name="response1"
								value={response.response1}
								onChange={handleInputChange}
								required
							/>
							<button type="submit" name="submit-response">
								Submit response
							</button>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Week1;
