import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";
import { db } from "../../Firebase";

const AssignmentFive = () => {
	const [item, setItem] = useState({
		isActive: "",
		weekNum: "",
		assignmentTitle: "",
	});

	useEffect(() => {
		db.collection("assignments")
			.doc("week5")
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					// console.log(data.isActive);
					setItem({
						isActive: data.isActive,
						weekNum: data.weekNum,
						assignmentTitle: data.assignmentTitle,
					});
				}
			})
			.catch(err => {
				console.log("Error retrieving data:", err);
			});
	}, []);

	const toggleActive = e => {
		setItem({ ...item, [e.target.name]: e.target.checked });
	};

	const handleUpdate = e => {
		db.collection("assignments").doc("week5").update({
			isActive: item.isActive,
		});
		const msg = document.getElementById(`${item.weekNum}`);
		msg.style.opacity = 1;
		setTimeout(() => {
			msg.style.opacity = 0;
		}, 1500);
	};

	return (
		<>
			<div className="assignment-items">
				<input
					type="checkbox"
					name="isActive"
					checked={item.isActive}
					onChange={toggleActive}
				/>
				<span>Week {item.weekNum}</span>
				<span>{item.assignmentTitle}</span>
				<span className="button-group">
					<button type="submit" name="update" onClick={handleUpdate}>
						Update
					</button>
					<Link to="/admin/assignments/edit-assignment-five">
						<button type="submit" name="edit">
							Edit
						</button>
					</Link>
				</span>
			</div>
			<div className="update-msg" id={item.weekNum}>
				Week {item.weekNum} has been{" "}
				{item.isActive ? <span>activated</span> : <span>deactivated</span>}
			</div>
		</>
	);
};

export default AssignmentFive;
