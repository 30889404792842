import React, { useState, useEffect } from "react";
import FormError from "./FormError";
import firebase from "./Firebase";

function Register(props) {
	const [displayName, setDisplayName] = useState("");
	const [email, setEmail] = useState("");
	const [passOne, setPassOne] = useState("");
	const [passTwo, setPassTwo] = useState("");
	const [courseID, setCourseID] = useState("");
	const [errMsg, setErrMsg] = useState(null);

	useEffect(() => {
		if (passOne !== passTwo) {
			setErrMsg("Passwords do not match");
		} else {
			setErrMsg(null);
		}
	}, [passOne, passTwo]);

	const handleSubmit = e => {
		const registrationInfo = {
			email: email,
			password: passOne,
			displayName: displayName,
			courseID: courseID,
		};
		e.preventDefault();

		firebase
			.auth()
			.createUserWithEmailAndPassword(
				registrationInfo.email,
				registrationInfo.password
			)
			.then(() => {
				props.registerUser(
					registrationInfo.displayName,
					registrationInfo.courseID
				);
			})
			.catch(err => {
				if (err.message != null) {
					setErrMsg(err.message);
				} else {
					setErrMsg(null);
				}
			});
	};

	return (
		<form className="mt-3" onSubmit={handleSubmit}>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-8">
						<h1 className="display-4 text-primary mt-3 mb-2">
							Create New Account
						</h1>
						{/* <h3 className="text-primary mt-3 mb-2">Create New Account</h3> */}
						<p>
							Please enter your Ryerson University username and a new password
							that is different from your my.Ryerson password.
						</p>
						<div className="card bg-light">
							<div className="card-body">
								<h3 className="font-weight-light mb-3">Register</h3>
								<div className="form-row">
									{errMsg !== null ? <FormError theMsg={errMsg} /> : null}
									<section className="col-sm-12 form-group">
										<label
											className="form-control-label sr-only"
											htmlFor="displayName"
										>
											Display Name
										</label>
										<input
											className="form-control"
											type="text"
											id="displayName"
											placeholder="Display Name"
											name="displayName"
											required
											value={displayName}
											onChange={e => setDisplayName(e.target.value)}
										/>
									</section>
								</div>
								<div className="form-row">
									<section className="col-sm-12 form-group">
										<label
											className="form-control-label sr-only"
											htmlFor="email"
										>
											Email
										</label>
										<input
											className="form-control"
											type="email"
											id="email"
											placeholder="Email Address"
											name="email"
											required
											value={email}
											onChange={e => setEmail(e.target.value)}
										/>
									</section>
								</div>
								<div className="form-row">
									<section className="col-sm-6 form-group">
										<label
											className="form-control-label sr-only"
											htmlFor="passOne"
										>
											Password
										</label>
										<input
											className="form-control"
											type="password"
											placeholder="Password"
											name="passOne"
											id="passOne"
											value={passOne}
											autoComplete="Password"
											onChange={e => setPassOne(e.target.value)}
										/>
									</section>
									<section className="col-sm-6 form-group">
										<label
											className="form-control-label sr-only"
											htmlFor="passTwo"
										>
											Repeat Password
										</label>
										<input
											className="form-control"
											type="password"
											placeholder="Repeat Password"
											name="passTwo"
											id="passTwo"
											required
											value={passTwo}
											autoComplete="Confirm Password"
											onChange={e => setPassTwo(e.target.value)}
										/>
									</section>
									<section className="col-sm-12 form-group">
										<label
											className="form-control-label sr-only"
											htmlFor="courseID"
										>
											Course ID
										</label>
										<input
											className="form-control"
											type="text"
											placeholder="Section"
											name="courseID"
											id="courseID"
											required
											value={courseID.toUpperCase()}
											autoComplete="courseID"
											onChange={e => setCourseID(e.target.value)}
										/>
									</section>
								</div>
								<div className="form-group text-right mb-0">
									<button className="btn btn-primary" type="submit">
										Submit
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
}

export default Register;
