import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router";
import { db } from "../Firebase";

const Week1Response = () => {
  // const { user } = props;
  // if (user != null) {
  //   uid = user.uid;
  // }
  const [response, setResponse] = useState({
    response1: ""
  });

  useEffect(() => {
    const docRef = db.collection("assignments").doc("week1");

    docRef
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          setResponse({
            response1: data.response1
          });
        } else {
          console.log("No such document");
        }
      })
      .catch(err => {
        console.log("Error retrieving data:", err);
      });
  }, []);

  const submitResponse = e => {
    // e.preventDefault();
    // const userRef = db.collection("users").doc(`${uid}`);
    // return userRef
    //   .update({
    //     weekOneCompleted: true
    //   })
    //   .then(() => {
    navigate("/profile");
    // });
  };

  return (
    <>
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-10 col-md-10 col-lg-8 col-xl-7 home-jl">
            <h1 className="display-4 text-primary mt-3 mb-2">
              {/* Week One Response */}
            </h1>
            <p className="db-textarea">{response.response1}</p>
            <button
              type="submit"
              name="mark-completed"
              onClick={submitResponse}
            >
              Go to Profile
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Week1Response;
