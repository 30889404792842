import React from "react";
// import Logo from "../images/Ryerson-rgb.png";
import { Zoom } from "react-slideshow-image";
import slide1 from "../images/slider-1.jpg";
import slide2 from "../images/slider-2.jpg";
import slide3 from "../images/slider-3.jpg";
import slide4 from "../images/slider-4.jpg";

const slideImages = [slide1, slide2, slide3, slide4];

// const properties = {
//   duration: 5000,
//   transitionDuration: 500,
//   infinite: true,
//   indicators: true,
//   arrows: true,
//   onChange: (oldIndex, newIndex) => {
//     // console.log(`slide transition from ${oldIndex} to ${newIndex}`);
//   }
// };

const zoomOutProperties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  scale: 0.4,
  arrows: true
};

const Slideshow = () => {
  return (
    <div className="slide-container">
      <Zoom {...zoomOutProperties}>
        {slideImages.map((each, index) => (
          <img
            key={index}
            style={{ width: "100%", height: "100%" }}
            src={each}
            alt="slider"
          />
        ))}
      </Zoom>
    </div>
    // <div className="slide-container">
    //   <Slide {...properties}>
    //     <div className="each-slide">
    //       <div style={{ backgroundImage: `url(${slideImages[0]})` }}>
    //         {/* <span>Slide 1</span> */}
    //       </div>
    //     </div>
    //     <div className="each-slide">
    //       <div style={{ backgroundImage: `url(${slideImages[1]})` }}>
    //         {/* <span>Slide 2</span> */}
    //       </div>
    //     </div>
    //     <div className="each-slide">
    //       <div style={{ backgroundImage: `url(${slideImages[2]})` }}>
    //         {/* <span>Slide 3</span> */}
    //       </div>
    //     </div>
    //   </Slide>
    // </div>
  );
};

const Home = props => {
  // const { user } = props;
  return (
    <>
      <Slideshow />
      <div className="container wrapper">
        <div className="fake-filler"></div>
        <div className="footer">
          <p className="disclaimer">
            Terronto Games is an educational simulation developed for the School
            of Creative Industries at Ryerson University. All rights reserved.
          </p>
          {/* <img src={Logo} alt="Ryerson logo" /> */}
        </div>
      </div>
    </>
  );
};

export default Home;
