import React, { useState } from "react";
// import { Link } from "@reach/router";
// import firebase from "../Firebase";
import { functions } from "../Firebase";
// import AdminNavigation from "./AdminNavigation";

function Auth() {
	const [adminEmail, setAdminEmail] = useState("");

	const msg = document.querySelector(".message");
	// console.log(idTokenResult.claims.admin);

	// useEffect(() => {
	//   db.collection("users").doc("week12");
	// }, [])

	// firebase
	//   .auth()
	//   .currentUser.updateProfile({
	//     display"Peter Jones"
	//   })
	//   .then(function() {
	//     console.log(`user has been updated!`);
	//   })
	//   .catch(function(error) {
	//     // An error happened.
	//   });

	const makeAdminSubmit = e => {
		e.preventDefault();
		// console.log(`submitting ${adminEmail}`);
		const addAdminRole = functions.httpsCallable("addAdminRole");
		addAdminRole({ email: adminEmail }).then(result => {
			// console.log(result);
			msg.innerHTML = result.data.message;
		});
	};

	const handleInputChange = e => {
		e.preventDefault();
		setAdminEmail(e.target.value);
	};

	return (
		<>
			<div className="container text-center">
				<div className="row justify-content-center">
					<div className="col-10 col-md-10 col-lg-8 col-xl-10">
						<h1 className="display-4 text-primary mt-3 mb-2">
							Role Assignment
						</h1>
						<form className="admin-actions" onSubmit={makeAdminSubmit}>
							<input
								type="email"
								placeholder="User email"
								id="adminEmail"
								onChange={handleInputChange}
								required
							/>
							<button>Make Admin</button>
						</form>
						<br />
						<p className="message"></p>
					</div>
				</div>
			</div>
		</>
	);
}

export default Auth;
