import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";
import { db } from "./Firebase";
import iconOne from "../images/icon-week1.png";
// import iconOneBlack from "../images/icon-week1-black.png";
import iconTwo from "../images/icon-week2.png";
// import iconTwoBlack from "../images/icon-week2-black.png";
import iconThree from "../images/icon-week3.png";
// import iconThreeBlack from "../images/icon-week3-black.png";
import iconFour from "../images/icon-week4.png";
// import iconFourBlack from "../images/icon-week4-black.png";
// import iconFive from "../images/icon-week5.png";
import iconPlane from "../images/icon-plane.png";
import iconSix from "../images/icon-week6.png";
// import iconSixBlack from "../images/icon-week6-black.png";
import iconSeven from "../images/icon-week7.png";
import iconEight from "../images/icon-week8.png";
import iconNine from "../images/icon-week9.png";
import iconTen from "../images/icon-week10.png";
import iconEleven from "../images/icon-week11.png";
import iconTwelve from "../images/icon-week12.png";
// import iconTwelveBlack from "../images/icon-week12-black.png";

const Workspace = props => {
	const { user } = props;
	let uid;
	if (user != null) {
		uid = user.uid;
	}
	// has the admin activated the assignment?
	const [week1, setWeek1] = useState(null);
	const [week2, setWeek2] = useState(null);
	const [week3, setWeek3] = useState(null);
	const [week4, setWeek4] = useState(null);
	const [week5, setWeek5] = useState(null);
	const [week6, setWeek6] = useState(null);
	const [week7, setWeek7] = useState(null);
	const [week8, setWeek8] = useState(null);
	const [week9, setWeek9] = useState(null);
	const [week10, setWeek10] = useState(null);
	const [week11, setWeek11] = useState(null);
	const [week12, setWeek12] = useState(null);

	// has the user completed the assignment?
	const [weekOneCompleted, setWeekOneCompleted] = useState(false);
	const [weekTwoCompleted, setWeekTwoCompleted] = useState(false);
	const [weekThreeCompleted, setWeekThreeCompleted] = useState(false);
	const [weekFourCompleted, setWeekFourCompleted] = useState(false);
	const [weekFiveCompleted, setWeekFiveCompleted] = useState(false);
	const [weekSixCompleted, setWeekSixCompleted] = useState(false);
	const [weekSevenCompleted, setWeekSevenCompleted] = useState(false);
	const [weekEightCompleted, setWeekEightCompleted] = useState(false);
	const [weekNineCompleted, setWeekNineCompleted] = useState(false);
	const [weekTenCompleted, setWeekTenCompleted] = useState(false);
	const [weekElevenCompleted, setWeekElevenCompleted] = useState(false);
	const [weekTwelveCompleted, setWeekTwelveCompleted] = useState(false);

	//  console.log(weekOneCompleted);

	useEffect(() => {
		// set the isActive status on each assignment
		db.collection("assignments")
			.doc("week1")
			.get()
			.then(doc => {
				if (doc.exists) {
					let data = doc.data();
					setWeek1(data.isActive);
				}
			})
			// })
			.catch(function (error) {
				console.log("Error getting documents: ", error);
			});
		db.collection("assignments")
			.doc("week2")
			.get()
			.then(doc => {
				if (doc.exists) {
					let data = doc.data();
					setWeek2(data.isActive);
				}
			})
			// })
			.catch(function (error) {
				console.log("Error getting documents: ", error);
			});
		db.collection("assignments")
			.doc("week3")
			.get()
			.then(doc => {
				if (doc.exists) {
					let data = doc.data();
					setWeek3(data.isActive);
				}
			})
			// })
			.catch(function (error) {
				console.log("Error getting documents: ", error);
			});
		db.collection("assignments")
			.doc("week4")
			.get()
			.then(doc => {
				if (doc.exists) {
					let data = doc.data();
					setWeek4(data.isActive);
				}
			})
			// })
			.catch(function (error) {
				console.log("Error getting documents: ", error);
			});
		db.collection("assignments")
			.doc("week5")
			.get()
			.then(doc => {
				if (doc.exists) {
					let data = doc.data();
					setWeek5(data.isActive);
				}
			})
			// })
			.catch(function (error) {
				console.log("Error getting documents: ", error);
			});
		db.collection("assignments")
			.doc("week6")
			.get()
			.then(doc => {
				if (doc.exists) {
					let data = doc.data();
					setWeek6(data.isActive);
				}
			})
			// })
			.catch(function (error) {
				console.log("Error getting documents: ", error);
			});
		db.collection("assignments")
			.doc("week7")
			.get()
			.then(doc => {
				if (doc.exists) {
					let data = doc.data();
					setWeek7(data.isActive);
				}
			})
			// })
			.catch(function (error) {
				console.log("Error getting documents: ", error);
			});
		db.collection("assignments")
			.doc("week8")
			.get()
			.then(doc => {
				if (doc.exists) {
					let data = doc.data();
					setWeek8(data.isActive);
				}
			})
			// })
			.catch(function (error) {
				console.log("Error getting documents: ", error);
			});
		db.collection("assignments")
			.doc("week9")
			.get()
			.then(doc => {
				if (doc.exists) {
					let data = doc.data();
					setWeek9(data.isActive);
				}
			})
			// })
			.catch(function (error) {
				console.log("Error getting documents: ", error);
			});
		db.collection("assignments")
			.doc("week10")
			.get()
			.then(doc => {
				if (doc.exists) {
					let data = doc.data();
					setWeek10(data.isActive);
				}
			})
			// })
			.catch(function (error) {
				console.log("Error getting documents: ", error);
			});
		db.collection("assignments")
			.doc("week11")
			.get()
			.then(doc => {
				if (doc.exists) {
					let data = doc.data();
					setWeek11(data.isActive);
				}
			})
			// })
			.catch(function (error) {
				console.log("Error getting documents: ", error);
			});
		db.collection("assignments")
			.doc("week12")
			.get()
			.then(doc => {
				if (doc.exists) {
					let data = doc.data();
					setWeek12(data.isActive);
				}
			})
			// })
			.catch(function (error) {
				console.log("Error getting documents: ", error);
			});

		// set the completion status of each assignment (user)
		const userRef = db.collection("users").doc(`${uid}`);
		userRef
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setWeekOneCompleted(data.weekOneCompleted || false);
					setWeekTwoCompleted(data.weekTwoCompleted || false);
					setWeekThreeCompleted(data.weekThreeCompleted || false);
					setWeekFourCompleted(data.weekFourCompleted || false);
					setWeekFiveCompleted(data.weekFiveCompleted || false);
					setWeekSixCompleted(data.weekSixCompleted || false);
					setWeekSevenCompleted(data.weekSevenCompleted || false);
					setWeekEightCompleted(data.weekEightCompleted || false);
					setWeekNineCompleted(data.weekNineCompleted || false);
					setWeekTenCompleted(data.weekTenCompleted || false);
					setWeekElevenCompleted(data.weekElevenCompleted || false);
					setWeekTwelveCompleted(data.weekTwelveCompleted || false);
				} else {
					// console.log("no such document");
				}
			})
			.catch(err => {
				console.log("Error getting document", err);
			});
	}, [uid, user]);

	return (
		<>
			<div className="container text-center">
				<div className="row justify-content-center">
					{/* <div className="col-10 col-md-10 col-lg-8 col-xl-10"> */}
					<div className="col-10">
						<h1 className="display-4 text-primary mt-3 mb-2">My Workspace</h1>
						<section className="workspace-weeks">
							{week1 ? (
								weekOneCompleted ? (
									<Link
										to="/workspace/assignments/week-one"
										className="assignment-link"
										tabIndex="1"
									>
										<div className="assignment-card is-completed">
											<div>
												<img
													src={iconOne}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>The</span>
											<span>Introduction</span>
										</div>
									</Link>
								) : (
									<Link
										to="/workspace/assignments/week-one"
										className="assignment-link"
										tabIndex="1"
									>
										<div className="assignment-card is-active">
											<div>
												<img
													src={iconOne}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>The</span>
											<span>Introduction</span>
										</div>
									</Link>
								)
							) : (
								<div className="assignment-card is-inactive">
									<div>
										<img src={iconOne} className="icon-img" alt="course icon" />
									</div>
									<span>The</span>
									<span>Introduction</span>
								</div>
							)}
							{week2 ? (
								weekTwoCompleted ? (
									<Link
										to="/workspace/assignments/week-two"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-completed">
											<div>
												<img
													src={iconTwo}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Oneself</span>
										</div>
									</Link>
								) : (
									<Link
										to="/workspace/assignments/week-two"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-active">
											<div className="icon-img">
												<img
													src={iconTwo}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Oneself</span>
										</div>
									</Link>
								)
							) : (
								<div className="assignment-card is-inactive">
									<div className="icon-img">
										<img src={iconTwo} className="icon-img" alt="course icon" />
									</div>
									<span>Managing</span>
									<span>Oneself</span>
								</div>
							)}
							{week3 ? (
								weekThreeCompleted ? (
									<Link
										to="/workspace/assignments/week-three"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-completed">
											<div>
												<img
													src={iconThree}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Around</span>
										</div>
									</Link>
								) : (
									<Link
										to="/workspace/assignments/week-three"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-active">
											<div>
												<img
													src={iconThree}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Around</span>
										</div>
									</Link>
								)
							) : (
								<div className="assignment-card is-inactive">
									<div>
										<img
											src={iconThree}
											className="icon-img"
											alt="course icon"
										/>
									</div>
									<span>Managing</span>
									<span>Around</span>
								</div>
							)}
							{week4 ? (
								weekFourCompleted ? (
									<Link
										to="/workspace/assignments/week-four"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-completed">
											<div>
												<img
													src={iconFour}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Up</span>
										</div>
									</Link>
								) : (
									<Link
										to="/workspace/assignments/week-four"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-active">
											<div>
												<img
													src={iconFour}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Up</span>
										</div>
									</Link>
								)
							) : (
								<div className="assignment-card is-inactive">
									<div>
										<img
											src={iconFour}
											className="icon-img"
											alt="course icon"
										/>
									</div>
									<span>Managing</span>
									<span>Up</span>
								</div>
							)}
							{week5 ? (
								weekFiveCompleted ? (
									<Link
										to="/workspace/assignments/week-five"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-completed">
											<div>
												<img
													src={iconPlane}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Remotely</span>
										</div>
									</Link>
								) : (
									<Link
										to="/workspace/assignments/week-five"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-active">
											<div>
												<img
													src={iconPlane}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Remotely</span>
										</div>
									</Link>
								)
							) : (
								<div className="assignment-card is-inactive">
									<div>
										<img
											src={iconPlane}
											className="icon-img"
											alt="course icon"
										/>
									</div>
									<span>Managing</span>
									<span>Remotely</span>
								</div>
							)}
							{week6 ? (
								weekSixCompleted ? (
									<Link
										to="/workspace/assignments/week-six"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-completed">
											<div>
												<img
													src={iconSix}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Status</span>
											<span>Update</span>
										</div>
									</Link>
								) : (
									<Link
										to="/workspace/assignments/week-six"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-active">
											<div>
												<img
													src={iconSix}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Status</span>
											<span>Update</span>
										</div>
									</Link>
								)
							) : (
								<div className="assignment-card is-inactive">
									<div>
										<img src={iconSix} className="icon-img" alt="course icon" />
									</div>
									<span>Status</span>
									<span>Update</span>
								</div>
							)}
							{week7 ? (
								weekSevenCompleted ? (
									<Link
										to="/workspace/assignments/week-seven"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-completed">
											<div>
												<img
													src={iconSeven}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Projects</span>
										</div>
									</Link>
								) : (
									<Link
										to="/workspace/assignments/week-seven"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-active">
											<div>
												<img
													src={iconSeven}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Projects</span>
										</div>
									</Link>
								)
							) : (
								<div className="assignment-card is-inactive">
									<div>
										<img
											src={iconSeven}
											className="icon-img"
											alt="course icon"
										/>
									</div>
									<span>Managing</span>
									<span>Projects</span>
								</div>
							)}
							{week8 ? (
								weekEightCompleted ? (
									<Link
										to="/workspace/assignments/week-eight"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-completed">
											<div>
												<img
													src={iconEight}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Diversity</span>
										</div>
									</Link>
								) : (
									<Link
										to="/workspace/assignments/week-eight"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-active">
											<div>
												<img
													src={iconEight}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Diversity</span>
										</div>
									</Link>
								)
							) : (
								<div className="assignment-card is-inactive">
									<div>
										<img
											src={iconEight}
											className="icon-img"
											alt="course icon"
										/>
									</div>
									<span>Managing</span>
									<span>Diversity</span>
								</div>
							)}
							{week9 ? (
								weekNineCompleted ? (
									<Link
										to="/workspace/assignments/week-nine"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-completed">
											<div>
												<img
													src={iconNine}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Finances</span>
										</div>
									</Link>
								) : (
									<Link
										to="/workspace/assignments/week-nine"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-active">
											<div>
												<img
													src={iconNine}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Finances</span>
										</div>
									</Link>
								)
							) : (
								<div className="assignment-card is-inactive">
									<div>
										<img
											src={iconNine}
											className="icon-img"
											alt="course icon"
										/>
									</div>
									<span>Managing</span>
									<span>Finances</span>
								</div>
							)}
							{week10 ? (
								weekTenCompleted ? (
									<Link
										to="/workspace/assignments/week-ten"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-completed">
											<div>
												<img
													src={iconTen}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Conflict</span>
										</div>
									</Link>
								) : (
									<Link
										to="/workspace/assignments/week-ten"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-active">
											<div>
												<img
													src={iconTen}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Conflict</span>
										</div>
									</Link>
								)
							) : (
								<div className="assignment-card is-inactive">
									<div>
										<img src={iconTen} className="icon-img" alt="course icon" />
									</div>
									<span>Managing</span>
									<span>Conflict</span>
								</div>
							)}
							{week11 ? (
								weekElevenCompleted ? (
									<Link
										to="/workspace/assignments/week-eleven"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-completed">
											<div>
												<img
													src={iconEleven}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Reflexivity</span>
										</div>
									</Link>
								) : (
									<Link
										to="/workspace/assignments/week-eleven"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-active">
											<div>
												<img
													src={iconEleven}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Managing</span>
											<span>Reflexivity</span>
										</div>
									</Link>
								)
							) : (
								<div className="assignment-card is-inactive">
									<div>
										<img
											src={iconEleven}
											className="icon-img"
											alt="course icon"
										/>
									</div>
									<span>Managing</span>
									<span>Reflexivity</span>
								</div>
							)}
							{week12 ? (
								weekTwelveCompleted ? (
									<Link
										to="/workspace/assignments/week-twelve"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-completed">
											<div>
												<img
													src={iconTwelve}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Status</span>
											<span>Update</span>
										</div>
									</Link>
								) : (
									<Link
										to="/workspace/assignments/week-twelve"
										className="assignment-link"
										tabIndex="2"
									>
										<div className="assignment-card is-active">
											<div>
												<img
													src={iconTwelve}
													className="icon-img"
													alt="course icon"
												/>
											</div>
											<span>Status</span>
											<span>Update</span>
										</div>
									</Link>
								)
							) : (
								<div className="assignment-card is-inactive">
									<div>
										<img
											src={iconTwelve}
											className="icon-img"
											alt="course icon"
										/>
									</div>
									<span>Status</span>
									<span>Update</span>
								</div>
							)}
						</section>
					</div>
				</div>
			</div>
		</>
	);
};

export default Workspace;
