import React, { useState, useEffect } from 'react';
import firebase from './Firebase';
import { db } from './Firebase';

const Profile = props => {
	const { user } = props;

	let uid;
	if (user != null) {
		uid = user.uid;
	}

	const [data, setData] = useState({
		displayName: '',
		courseID: '',
		email: '',
		weekOneResponse: '',
		weekOneInstructorResponse: '',
		weekOneCompleted: '',
		weekOneCompletedAt: '',
		weekTwoChoice: '',
		weekTwoCompleted: '',
		weekTwoCompletedAt: '',
		weekTwoStudentResponse: '',
		weekTwoInstructorResponse: '',
		weekThreeChoice: '',
		weekThreeCompleted: '',
		weekThreeCompletedAt: '',
		weekThreeStudentResponse: '',
		weekThreeInstructorResponse: '',
		weekFourChoice: '',
		weekFourCompleted: '',
		weekFourCompletedAt: '',
		weekFourStudentResponse: '',
		weekFourInstructorResponse: '',
		weekFiveChoice: '',
		weekFiveCompleted: '',
		weekFiveCompletedAt: '',
		weekFiveStudentResponse: '',
		weekFiveInstructorResponse: '',
		weekSixChoice: '',
		weekSixCompleted: '',
		weekSixCompletedAt: '',
		weekSixStudentResponse: '',
		weekSixInstructorResponse: '',
		weekSevenChoice: '',
		weekSevenCompleted: '',
		weekSevenCompletedAt: '',
		weekSevenStudentResponse: '',
		weekSevenInstructorResponse: '',
		weekEightChoice: '',
		weekEightCompleted: '',
		weekEightCompletedAt: '',
		weekEightStudentResponse: '',
		weekEightInstructorResponse: '',
		weekNineChoice: '',
		weekNineCompleted: '',
		weekNineCompletedAt: '',
		weekNineStudentResponse: '',
		weekNineInstructorResponse: '',
		weekTenChoice: '',
		weekTenCompleted: '',
		weekTenCompletedAt: '',
		weekTenStudentResponse: '',
		weekTenInstructorResponse: '',
		weekElevenChoice: '',
		weekElevenCompleted: '',
		weekElevenCompletedAt: '',
		weekElevenStudentResponse: '',
		weekElevenInstructorResponse: '',
		weekTwelveChoice: '',
		weekTwelveCompleted: '',
		weekTwelveCompletedAt: '',
		weekTwelveStudentResponse: '',
		weekTwelveInstructorResponse: ''
	});

	const [week1, setWeek1] = useState({
		isActive: '',
		weekNum: '',
		question1: '',
		question2: '',
		question3: '',
		question4: '',
		weekOneResponse: ''
	});
	const [week2, setWeek2] = useState({
		isActive: '',
		weekNum: '',
		question1: '',
		question2: '',
		question3: '',
		question4: '',
		weekTwoChoice: ''
	});
	const [week3, setWeek3] = useState({
		isActive: '',
		weekNum: '',
		question1: '',
		question2: '',
		question3: '',
		question4: '',
		weekThreeChoice: ''
	});
	const [week4, setWeek4] = useState({
		isActive: '',
		weekNum: '',
		question1: '',
		question2: '',
		question3: '',
		question4: '',
		weekFourChoice: ''
	});
	const [week5, setWeek5] = useState({
		isActive: '',
		weekNum: '',
		question1: '',
		question2: '',
		question3: '',
		question4: '',
		weekFiveChoice: ''
	});
	const [week6, setWeek6] = useState({
		isActive: '',
		weekNum: '',
		question1: '',
		question2: '',
		question3: '',
		question4: '',
		weekSixChoice: ''
	});
	const [week7, setWeek7] = useState({
		isActive: '',
		weekNum: '',
		question1: '',
		question2: '',
		question3: '',
		question4: '',
		weekSevenChoice: ''
	});
	const [week8, setWeek8] = useState({
		isActive: '',
		weekNum: '',
		question1: '',
		question2: '',
		question3: '',
		question4: '',
		weekEightChoice: ''
	});
	const [week9, setWeek9] = useState({
		isActive: '',
		weekNum: '',
		question1: '',
		question2: '',
		question3: '',
		question4: '',
		weekNineChoice: ''
	});
	const [week10, setWeek10] = useState({
		isActive: '',
		weekNum: '',
		question1: '',
		question2: '',
		question3: '',
		question4: '',
		weekTenChoice: ''
	});
	const [week11, setWeek11] = useState({
		isActive: '',
		weekNum: '',
		question1: '',
		question2: '',
		question3: '',
		question4: '',
		weekElevenChoice: ''
	});
	const [week12, setWeek12] = useState({
		isActive: '',
		weekNum: '',
		question1: '',
		question2: '',
		question3: '',
		question4: '',
		weekTwelveChoice: ''
	});

	const handleReset = () => {
		firebase
			.auth()
			.sendPasswordResetEmail(`${data.email}`)
			.then(() => {
				const resetBtn = document.getElementById('reset-btn');
				const resetMsg = document.getElementById('password-reset-msg');
				resetMsg.innerText =
					'Password reset link has been sent. Please check your email.';
				resetBtn.setAttribute('disabled', true);
				resetMsg.style.opacity = 1;
				setTimeout(() => {
					resetBtn.removeAttribute('disabled');
					resetMsg.style.opacity = 0;
					resetMsg.innerTxt = '';
				}, 2000);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		db.collection('users')
			.doc(`${uid}`)
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setData({
						displayName: data.displayName,
						courseID: data.courseID,
						email: data.email,
						weekOneResponse: data.weekOneResponse,
						weekOneInstructorResponse: data.weekOneInstructorResponse,
						weekOneCompleted: data.weekOneCompleted,
						weekOneCompletedAt: data.weekOneCompletedAt,
						weekTwoChoice: data.weekTwoChoice,
						weekTwoCompleted: data.weekTwoCompleted,
						weekTwoCompletedAt: data.weekTwoCompletedAt,
						weekTwoStudentResponse: data.weekTwoStudentResponse,
						weekTwoInstructorResponse: data.weekTwoInstructorResponse,
						weekThreeChoice: data.weekThreeChoice,
						weekThreeCompleted: data.weekThreeCompleted,
						weekThreeCompletedAt: data.weekThreeCompletedAt,
						weekThreeStudentResponse: data.weekThreeStudentResponse,
						weekThreeInstructorResponse: data.weekThreeInstructorResponse,
						weekFourChoice: data.weekFourChoice,
						weekFourCompleted: data.weekFourCompleted,
						weekFourCompletedAt: data.weekFourCompletedAt,
						weekFourStudentResponse: data.weekFourStudentResponse,
						weekFourInstructorResponse: data.weekFourInstructorResponse,
						weekFiveChoice: data.weekFiveChoice,
						weekFiveCompleted: data.weekFiveCompleted,
						weekFiveCompletedAt: data.weekFiveCompletedAt,
						weekFiveStudentResponse: data.weekFiveStudentResponse,
						weekFiveInstructorResponse: data.weekFiveInstructorResponse,
						weekSixChoice: data.weekSixChoice,
						weekSixCompleted: data.weekSixCompleted,
						weekSixCompletedAt: data.weekSixCompletedAt,
						weekSixStudentResponse: data.weekSixStudentResponse,
						weekSixInstructorResponse: data.weekSixInstructorResponse,
						weekSevenChoice: data.weekSevenChoice,
						weekSevenCompleted: data.weekSevenCompleted,
						weekSevenCompletedAt: data.weekSevenCompletedAt,
						weekSevenStudentResponse: data.weekSevenStudentResponse,
						weekSevenInstructorResponse: data.weekSevenInstructorResponse,
						weekEightChoice: data.weekEightChoice,
						weekEightCompleted: data.weekEightCompleted,
						weekEightCompletedAt: data.weekEightCompletedAt,
						weekEightStudentResponse: data.weekEightStudentResponse,
						weekEightInstructorResponse: data.weekEightInstructorResponse,
						weekNineChoice: data.weekNineChoice,
						weekNineCompleted: data.weekNineCompleted,
						weekNineCompletedAt: data.weekNineCompletedAt,
						weekNineStudentResponse: data.weekNineStudentResponse,
						weekNineInstructorResponse: data.weekNineInstructorResponse,
						weekTenChoice: data.weekTenChoice,
						weekTenCompleted: data.weekTenCompleted,
						weekTenCompletedAt: data.weekTenCompletedAt,
						weekTenStudentResponse: data.weekTenStudentResponse,
						weekTenInstructorResponse: data.weekTenInstructorResponse,
						weekElevenChoice: data.weekElevenChoice,
						weekElevenCompleted: data.weekElevenCompleted,
						weekElevenCompletedAt: data.weekElevenCompletedAt,
						weekElevenStudentResponse: data.weekElevenStudentResponse,
						weekElevenInstructorResponse: data.weekElevenInstructorResponse,
						weekTwelveChoice: data.weekTwelveChoice,
						weekTwelveCompleted: data.weekTwelveCompleted,
						weekTwelveCompletedAt: data.weekTwelveCompletedAt,
						weekTwelveStudentResponse: data.weekTwelveStudentResponse,
						weekTwelveInstructorResponse: data.weekTwelveInstructorResponse
					});
				} else {
					// console.log("No such document");
				}
			})
			.catch(err => {
				console.log('Error retrieving data:', err);
			});

		// get the assignments data for week1
		db.collection('assignments')
			.doc('week1')
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setWeek1({
						isActive: data.isActive,
						weekNum: data.weekNum,
						question1: data.question1,
						question2: data.question2,
						question3: data.question3,
						question4: data.question4
					});
				}
			})
			.catch(err => {
				console.log('Error retrieving data:', err);
			});
		// get the assignments data for week2
		db.collection('assignments')
			.doc('week2')
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setWeek2({
						isActive: data.isActive,
						weekNum: data.weekNum,
						choice1: data.choice1,
						choice2: data.choice2,
						choice3: data.choice3,
						choice4: data.choice4
					});
				}
			})
			.catch(err => {
				console.log('Error retrieving data:', err);
			});
		// get the assignments data for week3
		db.collection('assignments')
			.doc('week3')
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setWeek3({
						isActive: data.isActive,
						weekNum: data.weekNum,
						choice1: data.choice1,
						choice2: data.choice2,
						choice3: data.choice3,
						choice4: data.choice4
					});
				}
			})
			.catch(err => {
				console.log('Error retrieving data:', err);
			});
		// get the assignments data for week4
		db.collection('assignments')
			.doc('week4')
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setWeek4({
						isActive: data.isActive,
						weekNum: data.weekNum,
						choice1: data.choice1,
						choice2: data.choice2,
						choice3: data.choice3,
						choice4: data.choice4
					});
				}
			})
			.catch(err => {
				console.log('Error retrieving data:', err);
			});
		// get the assignments data for week5
		db.collection('assignments')
			.doc('week5')
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setWeek5({
						isActive: data.isActive,
						weekNum: data.weekNum,
						choice1: data.choice1,
						choice2: data.choice2,
						choice3: data.choice3,
						choice4: data.choice4
					});
				}
			})
			.catch(err => {
				console.log('Error retrieving data:', err);
			});
		// get the assignments data for week6
		db.collection('assignments')
			.doc('week6')
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setWeek6({
						isActive: data.isActive,
						weekNum: data.weekNum,
						choice1: data.choice1,
						choice2: data.choice2,
						choice3: data.choice3,
						choice4: data.choice4
					});
				}
			})
			.catch(err => {
				console.log('Error retrieving data:', err);
			});
		// get the assignments data for week7
		db.collection('assignments')
			.doc('week7')
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setWeek7({
						isActive: data.isActive,
						weekNum: data.weekNum,
						choice1: data.choice1,
						choice2: data.choice2,
						choice3: data.choice3,
						choice4: data.choice4
					});
				}
			})
			.catch(err => {
				console.log('Error retrieving data:', err);
			});
		// get the assignments data for week8
		db.collection('assignments')
			.doc('week8')
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setWeek8({
						isActive: data.isActive,
						weekNum: data.weekNum,
						choice1: data.choice1,
						choice2: data.choice2,
						choice3: data.choice3,
						choice4: data.choice4
					});
				}
			})
			.catch(err => {
				console.log('Error retrieving data:', err);
			});
		// get the assignments data for week9
		db.collection('assignments')
			.doc('week9')
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setWeek9({
						isActive: data.isActive,
						weekNum: data.weekNum,
						choice1: data.choice1,
						choice2: data.choice2,
						choice3: data.choice3,
						choice4: data.choice4
					});
				}
			})
			.catch(err => {
				console.log('Error retrieving data:', err);
			});
		// get the assignments data for week10
		db.collection('assignments')
			.doc('week10')
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setWeek10({
						isActive: data.isActive,
						weekNum: data.weekNum,
						choice1: data.choice1,
						choice2: data.choice2,
						choice3: data.choice3,
						choice4: data.choice4
					});
				}
			})
			.catch(err => {
				console.log('Error retrieving data:', err);
			});
		// get the assignments data for week11
		db.collection('assignments')
			.doc('week11')
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setWeek11({
						isActive: data.isActive,
						weekNum: data.weekNum,
						choice1: data.choice1,
						choice2: data.choice2,
						choice3: data.choice3,
						choice4: data.choice4
					});
				}
			})
			.catch(err => {
				console.log('Error retrieving data:', err);
			});
		// get the assignments data for week12
		db.collection('assignments')
			.doc('week12')
			.get()
			.then(doc => {
				if (doc.exists) {
					const data = doc.data();
					setWeek12({
						isActive: data.isActive,
						weekNum: data.weekNum,
						choice1: data.choice1,
						choice2: data.choice2,
						choice3: data.choice3,
						choice4: data.choice4
					});
				}
			})
			.catch(err => {
				console.log('Error retrieving data:', err);
			});
	}, [props, uid]);

	let completed = document.querySelectorAll('span.normal');
	for (let i = 0; i < completed.length; i++) {
		completed[i].innerHTML.split(' G').shift();
	}

	return (
		<>
			<div className='container text-center'>
				<div className='row justify-content-center'>
					<div className='col-12'>
						<h1 className='display-4 text-primary mt-3 mb-2'>
							Profile for {data.displayName}
						</h1>
						<section className='profile'>
							<p>Name: {data.displayName}</p>
							<p>Section: {data.courseID}</p>
							<p>
								Email: <a href={`mailto:${data.email}`}>{data.email}</a>
							</p>
							<div className='reset-password'>
								<button id='reset-btn' onClick={handleReset}>
									Reset Password{' '}
								</button>
								<span id='password-reset-msg'></span>
							</div>
						</section>
						<h1 className='display-4 text-primary mt-3 mb-2'>Assignments</h1>
						{data.weekOneResponse ? (
							<section className='assignment'>
								<p className='bold'>Week {week1.weekNum}</p>
								<p className='profile-p bold'>Week One Response:</p>
								<p className='db-textarea'>{data.weekOneResponse}</p>
								<p className='profile-p'>
									Completed on:{' '}
									<span className='normal'>
										{data.weekOneCompleted
											? new Date(data.weekOneCompletedAt.seconds * 1000)
													.toString()
													.split(' G')
													.shift()
											: 'NO'}
									</span>
								</p>
								{data.weekOneInstructorResponse ? (
									<>
										<br />
										<p className='profile-p bold'>Instructor Response:</p>
										<p className='db-textarea'>
											{data.weekOneInstructorResponse}
										</p>
									</>
								) : (
									''
								)}
							</section>
						) : (
							''
						)}
						{data.weekTwoCompleted ? (
							<section className='assignment'>
								<p className='bold'>Week {week2.weekNum}</p>
								<p className='profile-p bold'>You chose to:</p>
								{data.weekTwoChoice === 'choice1' ? (
									<p className='db-textarea'>{week2.choice1}</p>
								) : (
									''
								)}
								{data.weekTwoChoice === 'choice2' ? (
									<p className='db-textarea'>{week2.choice2}</p>
								) : (
									''
								)}
								{data.weekTwoChoice === 'choice3' ? (
									<p className='db-textarea'>{week2.choice3}</p>
								) : (
									''
								)}
								{data.weekTwoChoice === 'choice4' ? (
									<p className='db-textarea'>{week2.choice4}</p>
								) : (
									''
								)}
								<p className='profile-p bold'>Your response:</p>
								<p className='db-textarea'>{data.weekTwoStudentResponse}</p>
								<p className='profile-p'>
									Completed on:{' '}
									<span className='normal'>
										{data.weekTwoCompleted
											? new Date(
													data.weekTwoCompletedAt.seconds * 1000
											  ).toString()
											: 'NO'}
									</span>
								</p>
								{data.weekTwoInstructorResponse ? (
									<>
										<br />
										<p className='profile-p bold'>Instructor Response:</p>
										<p className='db-textarea'>
											{data.weekTwoInstructorResponse}
										</p>
									</>
								) : (
									''
								)}
							</section>
						) : (
							''
						)}
						{data.weekThreeCompleted ? (
							<section className='assignment'>
								<p className='bold'>Week {week3.weekNum}</p>
								<p className='profile-p bold'>You chose to:</p>
								{data.weekThreeChoice === 'choice1' ? (
									<p className='db-textarea'>{week3.choice1}</p>
								) : (
									''
								)}
								{data.weekThreeChoice === 'choice2' ? (
									<p className='db-textarea'>{week3.choice2}</p>
								) : (
									''
								)}
								{data.weekThreeChoice === 'choice3' ? (
									<p className='db-textarea'>{week3.choice3}</p>
								) : (
									''
								)}
								{data.weekThreeChoice === 'choice4' ? (
									<p className='db-textarea'>{week3.choice4}</p>
								) : (
									''
								)}
								<p className='profile-p bold'>Your response:</p>
								<p className='db-textarea'>{data.weekThreeStudentResponse}</p>
								<p className='profile-p'>
									Completed on:{' '}
									<span className='normal'>
										{data.weekThreeCompleted
											? new Date(
													data.weekThreeCompletedAt.seconds * 1000
											  ).toString()
											: 'NO'}
									</span>
								</p>
								{data.weekThreeInstructorResponse ? (
									<>
										<br />
										<p className='profile-p bold'>Instructor Response:</p>
										<p className='db-textarea'>
											{data.weekThreeInstructorResponse}
										</p>
									</>
								) : (
									''
								)}
							</section>
						) : (
							''
						)}
						{data.weekFourCompleted ? (
							<section className='assignment'>
								<p className='bold'>Week {week4.weekNum}</p>
								<p className='profile-p bold'>You chose to:</p>
								{data.weekFourChoice === 'choice1' ? (
									<p className='db-textarea'>{week4.choice1}</p>
								) : (
									''
								)}
								{data.weekFourChoice === 'choice2' ? (
									<p className='db-textarea'>{week4.choice2}</p>
								) : (
									''
								)}
								{data.weekFourChoice === 'choice3' ? (
									<p className='db-textarea'>{week4.choice3}</p>
								) : (
									''
								)}
								{data.weekFourChoice === 'choice4' ? (
									<p className='db-textarea'>{week4.choice4}</p>
								) : (
									''
								)}
								<p className='profile-p bold'>Your response:</p>
								<p className='db-textarea'>{data.weekFourStudentResponse}</p>
								<p className='profile-p'>
									Completed on:{' '}
									<span className='normal'>
										{data.weekFourCompleted
											? new Date(
													data.weekFourCompletedAt.seconds * 1000
											  ).toString()
											: 'NO'}
									</span>
								</p>
								{data.weekFourInstructorResponse ? (
									<>
										<br />
										<p className='profile-p bold'>Instructor Response:</p>
										<p className='db-textarea'>
											{data.weekFourInstructorResponse}
										</p>
									</>
								) : (
									''
								)}
							</section>
						) : (
							''
						)}
						{data.weekFiveCompleted ? (
							<section className='assignment'>
								<p className='bold'>Week {week5.weekNum}</p>
								<p className='profile-p bold'>You chose to:</p>
								{data.weekFiveChoice === 'choice1' ? (
									<p className='db-textarea'>{week5.choice1}</p>
								) : (
									''
								)}
								{data.weekFiveChoice === 'choice2' ? (
									<p className='db-textarea'>{week5.choice2}</p>
								) : (
									''
								)}
								{data.weekFiveChoice === 'choice3' ? (
									<p className='db-textarea'>{week5.choice3}</p>
								) : (
									''
								)}
								{data.weekFiveChoice === 'choice4' ? (
									<p className='db-textarea'>{week5.choice4}</p>
								) : (
									''
								)}
								<p className='profile-p bold'>Your response:</p>
								<p className='db-textarea'>{data.weekFiveStudentResponse}</p>
								<p className='profile-p'>
									Completed on:{' '}
									<span className='normal'>
										{data.weekFiveCompleted
											? new Date(
													data.weekFiveCompletedAt.seconds * 1000
											  ).toString()
											: 'NO'}
									</span>
								</p>
								{data.weekFiveInstructorResponse ? (
									<>
										<br />
										<p className='profile-p bold'>Instructor Response:</p>
										<p className='db-textarea'>
											{data.weekFiveInstructorResponse}
										</p>
									</>
								) : (
									''
								)}
							</section>
						) : (
							''
						)}
						{data.weekSixCompleted ? (
							<section className='assignment'>
								<p className='bold'>Week {week6.weekNum}</p>
								<p className='profile-p bold'>You chose to:</p>
								{data.weekSixChoice === 'choice1' ? (
									<p className='db-textarea'>{week6.choice1}</p>
								) : (
									''
								)}
								{data.weekSixChoice === 'choice2' ? (
									<p className='db-textarea'>{week6.choice2}</p>
								) : (
									''
								)}
								{data.weekSixChoice === 'choice3' ? (
									<p className='db-textarea'>{week6.choice3}</p>
								) : (
									''
								)}
								{data.weekSixChoice === 'choice4' ? (
									<p className='db-textarea'>{week6.choice4}</p>
								) : (
									''
								)}
								<p className='profile-p bold'>Your response:</p>
								<p className='db-textarea'>{data.weekSixStudentResponse}</p>
								<p className='profile-p'>
									Completed on:{' '}
									<span className='normal'>
										{data.weekSixCompleted
											? new Date(
													data.weekSixCompletedAt.seconds * 1000
											  ).toString()
											: 'NO'}
									</span>
								</p>
								{data.weekSixInstructorResponse ? (
									<>
										<br />
										<p className='profile-p bold'>Instructor Response:</p>
										<p className='db-textarea'>
											{data.weekSixInstructorResponse}
										</p>
									</>
								) : (
									''
								)}
							</section>
						) : (
							''
						)}
						{data.weekSevenCompleted ? (
							<section className='assignment'>
								<p className='bold'>Week {week7.weekNum}</p>
								<p className='profile-p bold'>You chose to:</p>
								{data.weekSevenChoice === 'choice1' ? (
									<p className='db-textarea'>{week7.choice1}</p>
								) : (
									''
								)}
								{data.weekSevenChoice === 'choice2' ? (
									<p className='db-textarea'>{week7.choice2}</p>
								) : (
									''
								)}
								{data.weekSevenChoice === 'choice3' ? (
									<p className='db-textarea'>{week7.choice3}</p>
								) : (
									''
								)}
								{data.weekSevenChoice === 'choice4' ? (
									<p className='db-textarea'>{week7.choice4}</p>
								) : (
									''
								)}
								<p className='profile-p bold'>Your response:</p>
								<p className='db-textarea'>{data.weekSevenStudentResponse}</p>
								<p className='profile-p'>
									Completed on:{' '}
									<span className='normal'>
										{data.weekSevenCompleted
											? new Date(
													data.weekSevenCompletedAt.seconds * 1000
											  ).toString()
											: 'NO'}
									</span>
								</p>
								{data.weekSevenInstructorResponse ? (
									<>
										<br />
										<p className='profile-p bold'>Instructor Response:</p>
										<p className='db-textarea'>
											{data.weekSevenInstructorResponse}
										</p>
									</>
								) : (
									''
								)}
							</section>
						) : (
							''
						)}
						{data.weekEightCompleted ? (
							<section className='assignment'>
								<p className='bold'>Week {week8.weekNum}</p>
								<p className='profile-p bold'>You chose to:</p>
								{data.weekEightChoice === 'choice1' ? (
									<p className='db-textarea'>{week8.choice1}</p>
								) : (
									''
								)}
								{data.weekEightChoice === 'choice2' ? (
									<p className='db-textarea'>{week8.choice2}</p>
								) : (
									''
								)}
								{data.weekEightChoice === 'choice3' ? (
									<p className='db-textarea'>{week8.choice3}</p>
								) : (
									''
								)}
								{data.weekEightChoice === 'choice4' ? (
									<p className='db-textarea'>{week8.choice4}</p>
								) : (
									''
								)}
								<p className='profile-p bold'>Your response:</p>
								<p className='db-textarea'>{data.weekEightStudentResponse}</p>
								<p className='profile-p'>
									Completed on:{' '}
									<span className='normal'>
										{data.weekEightCompleted
											? new Date(
													data.weekEightCompletedAt.seconds * 1000
											  ).toString()
											: 'NO'}
									</span>
								</p>
								{data.weekEightInstructorResponse ? (
									<>
										<br />
										<p className='profile-p bold'>Instructor Response:</p>
										<p className='db-textarea'>
											{data.weekEightInstructorResponse}
										</p>
									</>
								) : (
									''
								)}
							</section>
						) : (
							''
						)}
						{data.weekNineCompleted ? (
							<section className='assignment'>
								<p className='bold'>Week {week9.weekNum}</p>
								<p className='profile-p bold'>You chose to:</p>
								{data.weekNineChoice === 'choice1' ? (
									<p className='db-textarea'>{week9.choice1}</p>
								) : (
									''
								)}
								{data.weekNineChoice === 'choice2' ? (
									<p className='db-textarea'>{week9.choice2}</p>
								) : (
									''
								)}
								{data.weekNineChoice === 'choice3' ? (
									<p className='db-textarea'>{week9.choice3}</p>
								) : (
									''
								)}
								{data.weekNineChoice === 'choice4' ? (
									<p className='db-textarea'>{week9.choice4}</p>
								) : (
									''
								)}
								<p className='profile-p bold'>Your response:</p>
								<p className='db-textarea'>{data.weekNineStudentResponse}</p>
								<p className='profile-p'>
									Completed on:{' '}
									<span className='normal'>
										{data.weekNineCompleted
											? new Date(
													data.weekNineCompletedAt.seconds * 1000
											  ).toString()
											: 'NO'}
									</span>
								</p>
								{data.weekNineInstructorResponse ? (
									<>
										<br />
										<p className='profile-p bold'>Instructor Response:</p>
										<p className='db-textarea'>
											{data.weekNineInstructorResponse}
										</p>
									</>
								) : (
									''
								)}
							</section>
						) : (
							''
						)}
						{data.weekTenCompleted ? (
							<section className='assignment'>
								<p className='bold'>Week {week10.weekNum}</p>
								<p className='profile-p bold'>You chose to:</p>
								{data.weekTenChoice === 'choice1' ? (
									<p className='db-textarea'>{week10.choice1}</p>
								) : (
									''
								)}
								{data.weekTenChoice === 'choice2' ? (
									<p className='db-textarea'>{week10.choice2}</p>
								) : (
									''
								)}
								{data.weekTenChoice === 'choice3' ? (
									<p className='db-textarea'>{week10.choice3}</p>
								) : (
									''
								)}
								{data.weekTenChoice === 'choice4' ? (
									<p className='db-textarea'>{week10.choice4}</p>
								) : (
									''
								)}
								<p className='profile-p bold'>Your response:</p>
								<p className='db-textarea'>{data.weekTenStudentResponse}</p>
								<p className='profile-p'>
									Completed on:{' '}
									<span className='normal'>
										{data.weekTenCompleted
											? new Date(
													data.weekTenCompletedAt.seconds * 1000
											  ).toString()
											: 'NO'}
									</span>
								</p>
								{data.weekTenInstructorResponse ? (
									<>
										<br />
										<p className='profile-p bold'>Instructor Response:</p>
										<p className='db-textarea'>
											{data.weekTenInstructorResponse}
										</p>
									</>
								) : (
									''
								)}
							</section>
						) : (
							''
						)}
						{data.weekElevenCompleted ? (
							<section className='assignment'>
								<p className='bold'>Week {week11.weekNum}</p>
								<p className='profile-p bold'>You chose to:</p>
								{data.weekElevenChoice === 'choice1' ? (
									<p className='db-textarea'>{week11.choice1}</p>
								) : (
									''
								)}
								{data.weekElevenChoice === 'choice2' ? (
									<p className='db-textarea'>{week11.choice2}</p>
								) : (
									''
								)}
								{data.weekElevenChoice === 'choice3' ? (
									<p className='db-textarea'>{week11.choice3}</p>
								) : (
									''
								)}
								{data.weekElevenChoice === 'choice4' ? (
									<p className='db-textarea'>{week11.choice4}</p>
								) : (
									''
								)}
								<p className='profile-p bold'>Your response:</p>
								<p className='db-textarea'>{data.weekElevenStudentResponse}</p>
								<p className='profile-p'>
									Completed on:{' '}
									<span className='normal'>
										{data.weekElevenCompleted
											? new Date(
													data.weekElevenCompletedAt.seconds * 1000
											  ).toString()
											: 'NO'}
									</span>
								</p>
								{data.weekElevenInstructorResponse ? (
									<>
										<br />
										<p className='profile-p bold'>Instructor Response:</p>
										<p className='db-textarea'>
											{data.weekElevenInstructorResponse}
										</p>
									</>
								) : (
									''
								)}
							</section>
						) : (
							''
						)}
						{data.weekTwelveCompleted ? (
							<section className='assignment'>
								<p className='bold'>Week {week12.weekNum}</p>
								<p className='profile-p bold'>You chose to:</p>
								{data.weekTwelveChoice === 'choice1' ? (
									<p className='db-textarea'>{week12.choice1}</p>
								) : (
									''
								)}
								{data.weekTwelveChoice === 'choice2' ? (
									<p className='db-textarea'>{week12.choice2}</p>
								) : (
									''
								)}
								{data.weekTwelveChoice === 'choice3' ? (
									<p className='db-textarea'>{week12.choice3}</p>
								) : (
									''
								)}
								{data.weekTwelveChoice === 'choice4' ? (
									<p className='db-textarea'>{week12.choice4}</p>
								) : (
									''
								)}
								<p className='profile-p bold'>Your response:</p>
								<p className='db-textarea'>{data.weekTwelveStudentResponse}</p>
								<p className='profile-p'>
									Completed on:{' '}
									<span className='normal'>
										{data.weekTwelveCompleted
											? new Date(
													data.weekTwelveCompletedAt.seconds * 1000
											  ).toString()
											: 'NO'}
									</span>
								</p>
								{data.weekTwelveInstructorResponse ? (
									<>
										<br />
										<p className='profile-p bold'>Instructor Response:</p>
										<p className='db-textarea'>
											{data.weekTwelveInstructorResponse}
										</p>
									</>
								) : (
									''
								)}
							</section>
						) : (
							''
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Profile;
