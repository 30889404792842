import React from "react";

const About = props => {
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-10 col-md-10 col-lg-8 col-xl-7 home-jl">
          <h1 className="display-4 text-primary mt-3 mb-2  text-center">
            About Us
          </h1>
          <p className="text-center">Welcome to Terronto Games!</p>
          <p className="text-center">
            Located in Toronto, we are a fiercely Canadian indie game studio
            dedicated to creating the best horror experiences.
          </p>
          <p className="text-center">
            We believe that the truest horrors can only come from the mundane
            day-to-day routines we each experience. Some say that only the
            unusual is scary, but we believe that warping the usual out of its
            familiar shape is far more terrifying.
          </p>
          <p className="text-center">
            Since our launch in 2012, we have brought 5 games to our players
            across the globe. Our biggest hit to date,{" "}
            <span style={{ fontStyle: "italic" }}>TTC Commute Simulator</span>,
            embodies our core beliefs in its innovative exploration of the
            realities and fantasies of the transit system every Torontonian
            loves to hate.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
