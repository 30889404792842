import React, { useEffect } from "react";
import { db } from "./Firebase";

const Directory = () => {
	useEffect(() => {
		db.collection("users")
			.orderBy("courseID")
			.orderBy("displayName")
			.get()
			.then(querySnapshot => {
				querySnapshot.forEach(doc => {
					let li = document.createElement("li");
					let span1 = document.createElement("span");
					let span2 = document.createElement("span");
					let span3 = document.createElement("span");
					// let button = document.createElement("button");

					span1.innerText = `Section: ${doc.data().courseID}`;
					span1.classList.add("section");
					span2.innerText = `${doc.data().displayName}`;
					span2.classList.add("name");
					span3.innerHTML = `<a href="mailto:${doc.data().email}">${
						doc.data().email
					}</a>`;
					span3.classList.add("email");
					// button.setAttribute("type", "submit");
					// button.setAttribute("id", `${doc.id}`);
					// button.classList.add("button");
					// button.innerText = "";

					li.appendChild(span1);
					li.appendChild(span2);
					li.appendChild(span3);
					// li.appendChild(button);

					document.querySelector(".students").appendChild(li);
				});
			});
	}, []);

	return (
		<>
			<div className="container text-center">
				<div className="row justify-content-center">
					{/* <div className="col-10 col-md-10 col-lg-8 col-xl-7"> */}
					<div className="col-12">
						<h1 className="display-4 text-primary mt-3 mb-2">Directory</h1>
						<section className="students-list">
							<ul className="students"></ul>
						</section>
					</div>
				</div>
			</div>
		</>
	);
};

export default Directory;
